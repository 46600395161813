/** @format */

import base64 from "base-64";
import videojs from "video.js";
import { newImageIpPath } from "../../App";
export const getPlayList = (payload) => {
  let allChannelList = [];

  let selectedDrmCategory;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }

  var keyServer;

  let startOverUrl;

  let assetDetails = payload?.ASSET_IDs_BASED_ON_LCN?.map((item) => {
    return payload?.ASSET_DETAILS[item];
  });

  let catchup = 0;
  assetDetails?.length > 0 &&
    assetDetails?.map((a, index) => {
      if (selectedDrmCategory == 1) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let firstPartUrl = a?.assetUrl?.substring(0, pos);

        if (
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
          a?.catchupRequired == 1
        ) {
          catchup = 1;
          let timeStamp =
            payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg
              ?.startTimeTimestamp / 1000;

          startOverUrl = firstPartUrl + "-" + timeStamp + "-now.mpd";
        } else {
          catchup = 0;

          startOverUrl = firstPartUrl + ".mpd";
        }
        let url1 = firstPartUrl + ".mpd";

        let certificate;

        let widewineLicense = payload?.DRM_DETAILS?.data[0]?.widewineLicense?.trim();
        let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName?.trim();
        let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
        let drmId = a?.drmId;
        let PlayState = "1";
        let DrmSystem = "Widevine";
        let date = new Date();

        let month1 = date.getMonth() + 1;
        let nowStampString =
          date.getFullYear() +
          "-" +
          month1 +
          "-" +
          date.getDate() +
          " " +
          0 +
          ":" +
          date.getHours() +
          ":" +
          date.getMinutes();

        let ContentUrl = url1;
        let UniqueDeviceId = payload.SAVED_DEVICE_TOCKEN;
        let DeviceTypeName = payload.SAVED_DEVICE_TOCKEN;
        let LoginName = base64.encode(loginName);
        let Password = base64.encode(loginPassword);
        let KeyId = base64.encode(drmId);
        let UniqueDeviceIdEncode = base64.encode(UniqueDeviceId);

        let ContentUrlEncode = base64.encode(ContentUrl);
        let NowStamp = base64.encode(nowStampString);
        let DeviceTypeNameEncode = base64.encode(DeviceTypeName);

        keyServer =
          widewineLicense +
          "?" +
          "PlayState=" +
          PlayState +
          "&" +
          "DrmSystem=" +
          DrmSystem +
          "&" +
          "LoginName=" +
          LoginName +
          "&" +
          "Password=" +
          Password +
          "&" +
          "KeyId=" +
          KeyId +
          "&" +
          "UniqueDeviceId=" +
          UniqueDeviceIdEncode +
          "&" +
          "ContentUrl=" +
          ContentUrlEncode +
          "&" +
          "NowStamp=" +
          NowStamp +
          "&" +
          "DeviceTypeName=" +
          DeviceTypeNameEncode;
      } else if (selectedDrmCategory == 2) {
        var pos = a?.assetUrl?.lastIndexOf(".");
        let firstPartUrl = a?.assetUrl?.substring(0, pos);

        if (
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
          a?.catchupRequired == 1
        ) {
          catchup = 1;
          let timeStamp =
            payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg
              ?.startTimeTimestamp / 1000;

          startOverUrl = firstPartUrl + "-" + timeStamp + "-now.mpd";
        } else {
          catchup = 0;

          startOverUrl = firstPartUrl + ".mpd";
        }

        keyServer = payload?.AUTHENTICATION
          ? payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim() +
            "?assetId=" +
            a?.id +
            "&categoryId=1&userId=" +
            payload.USER_DETAILS?.id
          : payload?.DRM_DETAILS?.data[0]?.widewineLicense.trim();
      }

      let flag = 0;
      let date = new Date();

      let timestamp = date?.getTime();

      // ***************PACKAGE_DETAILS**************
      for (var p = 0; p < a?.packages?.length; p++) {
        let stopDate =
          payload?.PACKAGE_DETAILS?.[a?.packages?.[p]?.assetGroupId]?.stopDate;

        let stopDateTimeStamp = parseInt(Date.parse(stopDate));
        if (
          a?.packages[p]?.assetGroupId ==
            payload?.PACKAGE_DETAILS?.[a?.packages[p]?.assetGroupId]
              ?.packageId &&
          timestamp < stopDateTimeStamp
        ) {
          flag = 1;
        }
      }
      // ************ALACARTE_DETAILS***********************
      let stopDateAlacarte = payload?.ALACARTE_DETAILS?.[a?.id]?.expiryDate;
      let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

      if (
        a?.id == payload?.ALACARTE_DETAILS?.[a?.id]?.assetId &&
        payload?.ALACARTE_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampAlacarte
      ) {
        flag = 1;
      }
      // *****************PAY_PER_VIEW_DETAILS*************
      let stopDatePPV = payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.expiryDate;
      let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

      if (
        a?.id == payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.assetId &&
        // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampPPV
      ) {
        flag = 1;
      }

      let data = {
        // src: startOverUrl,

        // type: "application/dash+xml",
        // keySystems: {
        //   "com.widevine.alpha": keyServer,
        // },
        src: startOverUrl,
        type: "application/dash+xml",
        keySystems: {
          "com.widevine.alpha": {
            videoRobustness: "SW_SECURE_CRYPTO",

            getLicense: (emeOptions, keyMessage, callback) => {
              videojs.xhr(
                {
                  method: "POST",

                  url: keyServer,

                  responseType: "arraybuffer",

                  body: keyMessage,
                },

                (err, response, responseBody) => {
                  if (err) {
                    callback(err);

                    return;
                  }

                  callback(null, responseBody);
                }
              );
            },
          },
        },
        name: a?.title,
        id: a?.id,
      };
      let title = a?.title;

      let name = a?.title;
      let default_status = false;
      if (index == 4) {
        default_status = true;
      }
      // console.log("playListDetailsinPLayer1" + JSON.stringify(a));

      let dataForPoster = {};
      if (
        payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId] != undefined
      ) {
        dataForPoster = {
          id: a?.liveAssetsPostAds[0]?.adId,
          src:
            newImageIpPath +
            payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId]?.adv_url,
          time: Number(a?.liveAssetsPostAds[0]?.duration),
          closeButtonInMilisec: 2000,
        };
      }

      let VideoadConfig = {};

      if (payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId] != undefined) {
        let videoUrl =
          payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId]?.videoUrl;
        if (videoUrl != undefined && videoUrl != null) {
          if (!videoUrl?.includes("http")) {
            videoUrl = newImageIpPath + videoUrl;
          } else {
            videoUrl = videoUrl;
          }
        }

        if (Number(a?.liveAssetsVideoAds[0]?.isSkipRequired) !== 0) {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,

            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),

            skip: Number(a?.liveAssetsVideoAds[0]?.skipBeyond), // Corrected inequality operator
          };
        } else {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,
            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),
          };
        }
      }

      let PosteradConfig = [dataForPoster];
      let adConfig = [VideoadConfig];
      let sources = [data];
      allChannelList.push({
        sources,
        logo: a?.liveThumbnailPath + "thumbnail.png",
        poster: a?.liveThumbnailPath + "thumbnail.png",
        name,
        title,
        searchItem: title + a?.logicalChannelNumber,
        logicalChannelNumber: a?.logicalChannelNumber,
        epgTitle: payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.title,
        default: default_status,
        flag,
        id: a?.id,
        catchup,
        timeStamp:
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp,

        PosteradConfig: PosteradConfig,
        adConfig: adConfig,
      });
    });
  return allChannelList;
};

export const getPlayListForFairplay = (payload) => {
  let allChannelList = [];

  let selectedDrmCategory;
  if (payload?.DRM_DETAILS != null && payload?.DRM_DETAILS != undefined) {
    selectedDrmCategory = payload?.DRM_DETAILS.selectedDrmCategory;
  }

  var keyServer1;

  let startOverUrl2;
  var fairplayCertificate;
  let assetDetails = payload?.ASSET_IDs_BASED_ON_LCN?.map((item) => {
    return payload?.ASSET_DETAILS[item];
  });
  let catchup = 0;
  assetDetails?.length > 0 &&
    assetDetails?.map((a, index) => {
      if (selectedDrmCategory == 1) {
        var pos = a?.assetUrl?.lastIndexOf(".");

        let firstPartUrl = a?.assetUrl?.substring(0, pos);
        // let firstPartUrl = url?.substring(0, pos);
        if (
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
          a?.catchupRequired == 1
        ) {
          catchup = 1;
          let timeStamp =
            payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg
              ?.startTimeTimestamp / 1000;

          startOverUrl2 = firstPartUrl + "-" + timeStamp + "-now.m3u8";
        } else {
          catchup = 0;

          startOverUrl2 = firstPartUrl + ".m3u8";
        }

        let url2 = firstPartUrl + ".m3u8";

        let fairplayLicense = payload?.DRM_DETAILS?.data[0]?.fairplayLicense?.trim();
        fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();
        let loginName = payload?.DRM_DETAILS?.data[0]?.subscriberName?.trim();
        let loginPassword = payload?.DRM_DETAILS?.data[0]?.subscriberPassword;
        let drmId = a?.drmId;
        let PlayState = "1";
        let DrmSystem2 = "Fairplay";
        let date = new Date();

        let month1 = date.getMonth() + 1;
        let nowStampString =
          date.getFullYear() +
          "-" +
          month1 +
          "-" +
          date.getDate() +
          " " +
          0 +
          ":" +
          date.getHours() +
          ":" +
          date.getMinutes();

        let ContentUrl2 = url2;
        let UniqueDeviceId1 = payload?.SAVED_DEVICE_TOCKEN;
        let DeviceTypeName1 = payload?.DEVICE_NAME;
        let LoginName = base64.encode(loginName);
        let Password = base64.encode(loginPassword);
        let KeyId = base64.encode(drmId);
        let UniqueDeviceIdEncode1 = base64.encode(UniqueDeviceId1);

        let ContentUrlEncode1 = base64.encode(ContentUrl2);
        let NowStamp = base64.encode(nowStampString);
        let DeviceTypeNameEncode1 = base64.encode(DeviceTypeName1);

        keyServer1 =
          fairplayLicense +
          "?" +
          "PlayState=" +
          PlayState +
          "&" +
          "DrmSystem=" +
          DrmSystem2 +
          "&" +
          "LoginName=" +
          LoginName +
          "&" +
          "Password=" +
          Password +
          "&" +
          "KeyId=" +
          KeyId +
          "&" +
          "UniqueDeviceId=" +
          UniqueDeviceIdEncode1 +
          "&" +
          "ContentUrl=" +
          ContentUrlEncode1 +
          "&" +
          "NowStamp=" +
          NowStamp +
          "&" +
          "DeviceTypeName=" +
          DeviceTypeNameEncode1;
      } else if (selectedDrmCategory == 2) {
        var pos = a?.assetUrl?.lastIndexOf(".");

        let firstPartUrl = a?.assetUrl?.substring(0, pos);
        // let firstPartUrl = url?.substring(0, pos);
        if (
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != undefined &&
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg != null &&
          a?.catchupRequired == 1
        ) {
          catchup = 1;
          let timeStamp =
            payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg
              ?.startTimeTimestamp / 1000;

          startOverUrl2 = firstPartUrl + "-" + timeStamp + "-now.m3u8";
        } else {
          catchup = 0;

          startOverUrl2 = firstPartUrl + ".m3u8";
        }

        fairplayCertificate = payload?.DRM_DETAILS?.data[0]?.fairplayCertificate?.trim();

        keyServer1 = payload?.AUTHENTICATION
          ? payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim() +
            "?assetId=" +
            a?.id +
            "&categoryId=1&userId=" +
            payload.USER_DETAILS?.id
          : payload?.DRM_DETAILS?.data[0]?.fairplayLicense.trim();
      }
      let date = new Date();

      let flag = 0;
      let timestamp = date?.getTime();

      // ***************PACKAGE_DETAILS**************
      for (var p = 0; p < a?.packages?.length; p++) {
        let stopDate =
          payload?.PACKAGE_DETAILS?.[a?.packages?.[p]?.assetGroupId]?.stopDate;

        let stopDateTimeStamp = parseInt(Date.parse(stopDate));
        if (
          a?.packages[p]?.assetGroupId ==
            payload?.PACKAGE_DETAILS?.[a?.packages[p]?.assetGroupId]
              ?.packageId &&
          timestamp < stopDateTimeStamp
        ) {
          flag = 1;
        }
      }
      // ************ALACARTE_DETAILS***********************
      let stopDateAlacarte = payload?.ALACARTE_DETAILS?.[a?.id]?.expiryDate;
      let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

      if (
        a?.id == payload?.ALACARTE_DETAILS?.[a?.id]?.assetId &&
        payload?.ALACARTE_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampAlacarte
      ) {
        flag = 1;
      }
      // *****************PAY_PER_VIEW_DETAILS*************
      let stopDatePPV = payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.expiryDate;
      let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

      if (
        a?.id == payload?.PAY_PER_VIEW_DETAILS?.[1]?.[a?.id]?.assetId &&
        // payload?.PAY_PER_VIEW_DETAILS?.[a?.id]?.categoryId == 1 &&
        timestamp < stopDateTimeStampPPV
      ) {
        flag = 1;
      }
      function licenseRequestLoaded(stringbuffer) {
        const bytes = new Uint8Array(Math.ceil(stringbuffer.length / 2));
        for (let i = 0; i < bytes.length; i++) {
          bytes[i] = parseInt(stringbuffer.substr(i * 2, 2), 16);
        }
        return bytes;
      }
      let certificate;

      let data = {
        src: startOverUrl2,

        type: "application/x-mpegURL",
        keySystems: {
          "com.apple.fps.1_0": {
            videoRobustness: "SW_SECURE_CRYPTO",
            getCertificate: (emeOptions, callback) => {
              videojs.xhr(
                {
                  method: "GET",
                  url: fairplayCertificate,
                  responseType: "arraybuffer",
                  Pragma: "no-cache",
                  CacheControl: "no-cache",
                  ContentType: "text/xml; charset=utf-8",
                },
                (err, response, responseBody) => {
                  if (err) {
                    callback(err);
                    return;
                  }
                  certificate = new Uint8Array(responseBody);
                  callback(null, new Uint8Array(responseBody));
                }
              );
            },
            getLicense: (emeOptions, contentId, keyMessage, callback) => {
              videojs.xhr(
                {
                  method: "POST",
                  url: keyServer1,
                  responseType: "string",
                  body: keyMessage,
                  headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                  },
                },
                (err, response, responseBody) => {
                  if (err) {
                    callback(err);
                    return;
                  }
                  callback(null, licenseRequestLoaded(responseBody));
                }
              );
            },
          },
        },
        name: a?.title,
        id: a?.id,
      };

      let dataForPoster = {};
      if (
        payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId] != undefined
      ) {
        dataForPoster = {
          id: a?.liveAssetsPostAds[0]?.adId,
          src:
            newImageIpPath +
            payload?.GENERAl_POST_ADS[a?.liveAssetsPostAds[0]?.adId]?.adv_url,
          time: Number(a?.liveAssetsPostAds[0]?.duration),
          closeButtonInMilisec: 2000,
        };
      }

      let VideoadConfig = {};

      if (payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId] != undefined) {
        let videoUrl =
          payload?.VIDEO_ADS[a?.liveAssetsVideoAds[0]?.adId]?.videoUrl;
        if (videoUrl != undefined && videoUrl != null) {
          if (!videoUrl?.includes("http")) {
            videoUrl = newImageIpPath + videoUrl;
          } else {
            videoUrl = videoUrl;
          }
        }

        if (Number(a?.liveAssetsVideoAds[0]?.isSkipRequired) !== 0) {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,

            src: videoUrl,

            time: Number(a?.liveAssetsVideoAds[0]?.duration),

            skip: Number(a?.liveAssetsVideoAds[0]?.skipBeyond), // Corrected inequality operator
          };
        } else {
          VideoadConfig = {
            id: a?.liveAssetsVideoAds[0]?.adId,

            src: videoUrl,
            time: Number(a?.liveAssetsVideoAds[0]?.duration),
          };
        }
      }

      let PosteradConfig = [dataForPoster];
      let adConfig = [VideoadConfig];
      let title = a?.title;

      let name = a?.title;
      let default_status = false;
      if (index == 4) {
        default_status = true;
      }

      let sources = [data];
      allChannelList.push({
        sources,
        logo: a?.liveThumbnailPath + "thumbnail.png",
        poster: a?.liveThumbnailPath + "thumbnail.png",
        name,
        title,
        searchItem: title + a?.logicalChannelNumber,
        logicalChannelNumber: a?.logicalChannelNumber,
        epgTitle: payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.title,
        default: default_status,
        flag,
        id: a?.id,
        catchup,
        timeStamp:
          payload?.LATEST_EPG_DETAILS?.[a?.id]?.currentEpg?.startTimeTimestamp,

        PosteradConfig: PosteradConfig,
        adConfig: adConfig,
      });
    });
  return allChannelList;
};
