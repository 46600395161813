import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import NewLoadingPage from "../NewLoadingPage";
import * as DATA_ANALYTICS from "../../Redux/DATA-analytics/actions.js";
import {
  configurationSelector,
  continueWatchingSelector,
  watchedHistorySelector,
} from "../../Redux/DATA-analytics/selector.js";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors.js";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import swal from "sweetalert";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors.js";
import { CloseButton, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverIpPath } from "../IpConstantFile/IpConstantFile.js";
import { HeadersAcessToken } from "../HeadersAcessToken.js";
import { getDeviceDetailsSelector } from "../../Redux/deviceRedux/selectors.js";
import * as AUTHENTICATION_ACTIONS from "../../Redux/authentication/actions";
import * as ACTION_TYPESS from "../../Redux/platforms/actions";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../../Redux/package&subscriptions/actions";
import MainHeader from "../MainHeader.js";
import store from "./../../Redux/store.js";
import { SK } from "./../../Redux/Search/actions";
import CryptoJS from "crypto-js";
import axiosInstance from "../../utils/axiosInstance.js";

var qs = require("qs");
const SKK = SK;
export const encryptData = (data) => {
  const jsonString = JSON.stringify(data);
  return CryptoJS.AES.encrypt(jsonString, SKK).toString();
};
const decryptData = (cipherText) => {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, SKK);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  } catch (error) {
    console.log("Decryption failed:", error);
    return cipherText;
  }
};
const Settings = ({
  CONFIGURATION_DAS,
  PLATFORMS,
  CURRENT_PLATFORM,
  CONTINUE_WATCHING_DATA,
  USER_DETAILS,
  DEVICE_DETAILS,

  setSearchState,
  setSearchKey,
  setClearSearchState,
  clearSearchState,
  selectedCategory,
  setSelectedCategory,
  AUTHENTICATION,
  WATCHES_HISTORY_DATA,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  console.log("WATCHES_HISTORY_DATA" + JSON.stringify(WATCHES_HISTORY_DATA));
  const [isContinueWatchingEnabled, setIsContinueWatchingEnabled] = useState(
    USER_DETAILS?.viewing_analytics_status
  );
  const [isWatchesHistoryEnabled, setIsWatchesHistoryEnabled] = useState(
    USER_DETAILS?.watchHistoryStatus
  );
  setSelectedCategory(null);
  let defaultLandingPage = CURRENT_PLATFORM?.defaultLandingPage;
  useEffect(() => {
    if (AUTHENTICATION == false) {
      history.replace("/login");
    }
  }, [AUTHENTICATION]);
  const closeSettings = () => {
    // history.goBack();
    history.push("/container");

    if (defaultLandingPage == 1) {
      setSelectedCategory(1);
    }

    if (defaultLandingPage == 2) {
      setSelectedCategory(2);
    }

    if (defaultLandingPage == 3) {
      setSelectedCategory(3);
    }
  };

  const RemoveAllFromContinueWatching = () => {
    // Function to remove all items from continue watching
    Swal.fire({
      title: "Are you sure?",
      text: "Remove all from Continue Watching!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          DATA_ANALYTICS.removeContinueWatchingStart({
            dataForRemoveContinueWatching: {
              platformId: PLATFORMS?.currentPlatformId,
              profileId: 1,
              userId: USER_DETAILS?.id,
              type: "All",
            },
            ServerIp: CONFIGURATION_DAS?.analyticsIp,
          })
        );
        swal("Deleted!", {
          icon: "success",
        });
      }
    });
  };
  const RemoveAllFromWatchesHistory = () => {
    // Function to remove all items from continue watching
    Swal.fire({
      title: "Are you sure?",
      text: "Remove all from Watches History!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          DATA_ANALYTICS.removeWatchesHistoryStart({
            dataForRemoveContinueWatching: {
              platformId: PLATFORMS?.currentPlatformId,
              profileId: 1,
              userId: USER_DETAILS?.id,
              type: "All",
            },
            ServerIp: CONFIGURATION_DAS?.analyticsIp,
          })
        );
        swal("Deleted!", {
          icon: "success",
        });
      }
    });
  };
  const DeleteAccount = () => {
    // Function to remove all items from continue watching
    setIsOpen(true);
  };
  const [userId, setUserId] = useState(USER_DETAILS?.userId);
  const [password, setPassword] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const closeModel = () => {
    setIsOpen(false);
    setPassword("");
  };
  const handleValidation = () => {
    // Add any validation logic if needed
    // Return true if validation passes, false otherwise
    return true;
  };
  let userData = null;
  let logoutData;

  if (USER_DETAILS != undefined && USER_DETAILS != null) {
    logoutData = {
      deviceId: DEVICE_DETAILS?.deviceToken,
      userId: USER_DETAILS?.id,
      orgId: USER_DETAILS?.orgId,
      history: history,
    };
  }
  const handleDeactivateUser = (e) => {
    e.preventDefault();

    if (handleValidation() && !isInputEmpty) {
      // Perform user deactivation logic
      userData = {
        id: userId,
        password: password,
      };

      let access_data = store.getState();
      let access_token =
        access_data?.authenticationReducer?.loginDetails?.access_token;

      axiosInstance()
        .post(
          "/delete/accountV2",
          // window.location.protocol +
          //   "//" +
          //   window.location.hostname +
          //   "/soapboxwebws/delete/accountV2",
          userData,
          {
            headers: HeadersAcessToken(access_token),
          }
        )
        .then((response) => {
          // console.log("API Response:", response);
          let decryptedData = decryptData(response.data);
          Swal.fire({
            icon: "success",
            background: "black",
            text: decryptedData.message,
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          }).then(() => {
            setUserId("");
            setPassword("");
            userData = null;
            setIsInputEmpty(true);
            setIsOpen(false);
            dispatch(ACTION_TYPESS.getPlatformsListStart());
            // dispatch(LIVE_ACTION.getGeneralSettingsStart({ account_id: 0 }));
            dispatch(AUTHENTICATION_ACTIONS.loginStausFalse());

            dispatch(AUTHENTICATION_ACTIONS.logoutStart(logoutData));
            dispatch(PACKAGE_SUBSCRIPTION_ACTION.makePackageDetailsEmpty());
          });
        })
        .catch((error) => {
          let decryptedErrorData = decryptData(error.response.data);

          Swal.fire({
            icon: "warning",
            background: "black",
            text: decryptedErrorData.message,
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            button: "OK",
          });
        });
    }
  };

  const handleInputChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserId(trimmedValue);
    setIsInputEmpty(trimmedValue === "" || password === "");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsInputEmpty(userId === "" || e.target.value === "");
  };

  const handleToggleContinueWatchingEnable = () => {
    // Function to enable continue watching
    dispatch(
      DATA_ANALYTICS.viewAnalyticsStatusStart({
        dataForViewAnalyticsStatusStart: {
          userId: USER_DETAILS?.id,
          viewing_analytics_status: 1,
          updatedBy: USER_DETAILS?.id,
          type: 1,
        },
        account_id: CURRENT_PLATFORM?.orgId,
      })
    );
    setIsContinueWatchingEnabled(!isContinueWatchingEnabled);
  };

  const handleToggleContinueWatchingDisable = () => {
    // Function to disable continue watching
    dispatch(
      DATA_ANALYTICS.viewAnalyticsStatusStart({
        dataForViewAnalyticsStatusStart: {
          userId: USER_DETAILS?.id,
          viewing_analytics_status: 0,
          updatedBy: USER_DETAILS?.id,
          type: 1,
        },
        account_id: CURRENT_PLATFORM?.orgId,
      })
    );
    setIsContinueWatchingEnabled(!isContinueWatchingEnabled);
  };

  const handleToggleWatchesHistoryEnable = () => {
    // Function to enable continue watching
    dispatch(
      DATA_ANALYTICS.viewAnalyticsStatusStart({
        dataForViewAnalyticsStatusStart: {
          userId: USER_DETAILS?.id,
          watchHistoryStatus: 1,
          updatedBy: USER_DETAILS?.id,
          type: 2,
        },
        account_id: CURRENT_PLATFORM?.orgId,
      })
    );
    setIsWatchesHistoryEnabled(!isWatchesHistoryEnabled);
  };

  const handleToggleWatchesHistoryDisable = () => {
    // Function to disable continue watching
    dispatch(
      DATA_ANALYTICS.viewAnalyticsStatusStart({
        dataForViewAnalyticsStatusStart: {
          userId: USER_DETAILS?.id,
          watchHistoryStatus: 0,
          updatedBy: USER_DETAILS?.id,
          type: 2,
        },
        account_id: CURRENT_PLATFORM?.orgId,
      })
    );
    setIsWatchesHistoryEnabled(!isWatchesHistoryEnabled);
  };
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div>
      <MainHeader
        setSearchState={setSearchState}
        setSearchKey={setSearchKey}
        setClearSearchState={setClearSearchState}
        clearSearchState={clearSearchState}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div style={{ height: "80px" }}></div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card bg-dark text-white">
            <div className="card-body">
              <div
                className="close-icon"
                style={{ position: "absolute", top: "10px", right: "10px" }}
                onClick={() => closeSettings()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24px"
                  height="24px"
                  cursor="pointer"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <div className="card-body">
                {/* Close Icon */}

                <h5
                  style={{
                    fontFamily: "Montserrat,sans-serif",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  className="card-title text-center mb-4"
                ></h5>
                <div className="widget-content widget-content-area">
                  <div className="table-responsive">
                    {/* Table */}
                    <table className="table table-bordered mb-4">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              fontWeight: "bold",
                              border: "1px solid white",
                            }}
                          >
                            Enable continue watching
                          </td>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              textAlign: "center",
                              border: "1px solid white",
                            }}
                            onClick={() =>
                              isContinueWatchingEnabled
                                ? handleToggleContinueWatchingDisable()
                                : handleToggleContinueWatchingEnable()
                            }
                          >
                            {isContinueWatchingEnabled ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check-square"
                                cursor="pointer"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                              </svg>
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                                cursor="pointer"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                              </svg>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              fontWeight: "bold",
                              border: "1px solid white",
                            }}
                          >
                            Enable Watched History
                          </td>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              textAlign: "center",
                              border: "1px solid white",
                            }}
                            onClick={() =>
                              isWatchesHistoryEnabled
                                ? handleToggleWatchesHistoryDisable()
                                : handleToggleWatchesHistoryEnable()
                            }
                          >
                            {isWatchesHistoryEnabled ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check-square"
                                cursor="pointer"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                              </svg>
                            ) : (
                              <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="css-i6dzq1"
                                cursor="pointer"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                              </svg>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              fontWeight: "bold",
                              border: "1px solid white",
                            }}
                          >
                            Clear partially watched assets
                          </td>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              textAlign: "center",
                              border: "1px solid white",
                            }}
                          >
                            {CONTINUE_WATCHING_DATA?.every(
                              (item) => item.videoDeleted === 1
                            ) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                                style={{ cursor: "not-allowed" }}
                              >
                                <path
                                  d="M 10 2 L 9 3 L 3 3 L 3 5 L 4.109375 5 L 5.8925781 20.255859 L 5.8925781 20.263672 C 6.023602 21.250335 6.8803207 22 7.875 22 L 16.123047 22 C 17.117726 22 17.974445 21.250322 18.105469 20.263672 L 18.107422 20.255859 L 19.890625 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 6.125 5 L 17.875 5 L 16.123047 20 L 7.875 20 L 6.125 5 z"
                                  fill="grey"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                                cursor="pointer"
                                onClick={() => RemoveAllFromContinueWatching()}
                              >
                                <path
                                  d="M 10 2 L 9 3 L 3 3 L 3 5 L 4.109375 5 L 5.8925781 20.255859 L 5.8925781 20.263672 C 6.023602 21.250335 6.8803207 22 7.875 22 L 16.123047 22 C 17.117726 22 17.974445 21.250322 18.105469 20.263672 L 18.107422 20.255859 L 19.890625 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 6.125 5 L 17.875 5 L 16.123047 20 L 7.875 20 L 6.125 5 z"
                                  fill="white"
                                />
                              </svg>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              fontWeight: "bold",
                              border: "1px solid white",
                            }}
                          >
                            Clear all watched history
                          </td>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              textAlign: "center",
                              border: "1px solid white",
                            }}
                          >
                            {Array.isArray(WATCHES_HISTORY_DATA) &&
                            WATCHES_HISTORY_DATA.length > 0 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                                cursor="pointer"
                                onClick={() => RemoveAllFromWatchesHistory()}
                              >
                                <path
                                  d="M 10 2 L 9 3 L 3 3 L 3 5 L 4.109375 5 L 5.8925781 20.255859 L 5.8925781 20.263672 C 6.023602 21.250335 6.8803207 22 7.875 22 L 16.123047 22 C 17.117726 22 17.974445 21.250322 18.105469 20.263672 L 18.107422 20.255859 L 19.890625 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 6.125 5 L 17.875 5 L 16.123047 20 L 7.875 20 L 6.125 5 z"
                                  fill="white"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                                style={{ cursor: "not-allowed" }}
                              >
                                <path
                                  d="M 10 2 L 9 3 L 3 3 L 3 5 L 4.109375 5 L 5.8925781 20.255859 L 5.8925781 20.263672 C 6.023602 21.250335 6.8803207 22 7.875 22 L 16.123047 22 C 17.117726 22 17.974445 21.250322 18.105469 20.263672 L 18.107422 20.255859 L 19.890625 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 6.125 5 L 17.875 5 L 16.123047 20 L 7.875 20 L 6.125 5 z"
                                  fill="grey"
                                />
                              </svg>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              fontWeight: "bold",
                              border: "1px solid white",
                            }}
                          >
                            Delete Account
                          </td>
                          <td
                            style={{
                              background: "black",
                              color: "white",
                              textAlign: "center",
                              border: "1px solid white",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24px"
                              height="24px"
                              cursor="pointer"
                              onClick={() => DeleteAccount()}
                            >
                              <path
                                d="M 10 2 L 9 3 L 3 3 L 3 5 L 4.109375 5 L 5.8925781 20.255859 L 5.8925781 20.263672 C 6.023602 21.250335 6.8803207 22 7.875 22 L 16.123047 22 C 17.117726 22 17.974445 21.250322 18.105469 20.263672 L 18.107422 20.255859 L 19.890625 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 6.125 5 L 17.875 5 L 16.123047 20 L 7.875 20 L 6.125 5 z"
                                fill="white"
                              />
                            </svg>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Version 2.5.0
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalIsOpen}
        onHide={() => closeModel()}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {" "}
          <CloseButton variant="white" onClick={() => closeModel()} />
        </Modal.Header>
        <Modal.Body>
          <div className="container justify-content-center col-lg-6">
            <div className="card bg-dark text-white">
              <div className="card-body">
                <h5
                  className="card-title text-center mb-4"
                  style={{ color: "red", alignItems: "center" }}
                >
                  Delete Account
                </h5>
                <p className="card-text" style={{ textAlign: "center" }}>
                  {/* By deleting the account, you will lose all your data and
                  subscriptions. */}
                  Are you sure you want to delete account?
                </p>
                <form onSubmit={handleDeactivateUser}>
                  <div className="form-group text-center mx-auto">
                    <label htmlFor="userId">
                      USER ID
                      <span style={{ color: "red", fontSize: "1.5em" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="userId"
                      value={userId}
                      disabled
                      onChange={handleInputChange}
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        width: "350px", // Set a fixed width
                        margin: "0 auto", // Center-align
                      }}
                    />
                  </div>

                  <div className="form-group text-center mx-auto position-relative">
                    {" "}
                    {/* Added position-relative class */}
                    <label htmlFor="password">
                      Password
                      <span style={{ color: "red", fontSize: "1.5em" }}>*</span>
                    </label>
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        width: "350px", // Set a fixed width
                        paddingRight: "40px", // Adjusted paddingRight to accommodate the eye icon
                        margin: "0 auto", // Center-align
                      }}
                    />
                    <span
                      className="pass-eye1"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "70%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {" "}
                      {/* Positioned the eye icon absolutely */}
                      {isPasswordVisible ? (
                        <i
                          className="bi bi-eye-slash"
                          style={{ color: "gray" }}
                          onClick={handleTogglePasswordVisibility}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye"
                          onClick={handleTogglePasswordVisibility}
                          style={{ color: "gray" }}
                        ></i>
                      )}
                    </span>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-danger rounded"
                      disabled={isInputEmpty}
                    >
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  CONFIGURATION_DAS: configurationSelector,
  PLATFORMS: platformsSelector,
  CURRENT_PLATFORM: getCurrentPlatformSelector,
  CONTINUE_WATCHING_DATA: continueWatchingSelector,
  USER_DETAILS: userDetailsSelector,
  DEVICE_DETAILS: getDeviceDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  WATCHES_HISTORY_DATA: watchedHistorySelector,
});

export default connect(mapStateToProps)(Settings);
