/** @format */

import React, { useEffect, useRef, useState } from "react";
import { createStructuredSelector } from "reselect";
import * as VIDEO_ACTION from "./../../Redux/VideoRedux/action";
import * as DATA_ACTION from "./../../Redux/Notication Assets redux/actions";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import {
  epgSelector,
  epgDateSelector,
  epgStateSelector,
  getEpgLoadingSelector,
  getEpgLoadingStatusSelector,
} from "../../Redux/EPG/seleector";
import * as ACTION_TYPE from "../../Redux/EPG/action";
import "react-responsive-modal/styles.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./../MoviesScreens/movieScreenModal.css";

import {
  useEpg,
  Epg,
  Layout,
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  ChannelBox,
  ChannelLogo,
  useProgram,
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDividers,
  useTimeline,
} from "planby";
import LoadingPage from "../LoadingPage";
import { connect, useDispatch } from "react-redux";
import NewLoadingPage from "../NewLoadingPage";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import {
  getCurrentPlatformSelector,
  platformsSelector,
} from "../../Redux/platforms/selectors";
import {
  getLiveAssetsDataSelector,
  getLiveLoadingSelector,
} from "../../Redux/Notication Assets redux/selectors";
import { playIcon } from "../IpConstantFile/IpConstantFile";
import DetailsPageProgressBar from "../DetailsPageProgressBar";
import AdsPage from "../AdsPage";
import EpgTabScroll from "./EpgTabScroll";
import { getIsCatchupRequiredSelector } from "../../Redux/VideoRedux/selector";
import { fontSize } from "@mui/system";
import { CloseButton, Modal } from "react-bootstrap";
import EpgLoadingPage from "./EpgLoadingPage";
import {
  allLiveAssetSelector,
  getGeneralAdsDetailSelector,
} from "../../Redux/AllLiveDetails/selectors";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../Redux/package&subscriptions/selector";
import { newImageIpPath } from "../../App";
import { getSendingDataOfPosteradsAsync } from "../../services/datAnalytics/dataAnalytics";
import { getDeviceTokenSelector } from "../../Redux/deviceRedux/selectors";
import { configurationSelector } from "../../Redux/DATA-analytics/selector";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors";

const theme = {
  primary: {
    600: "rgb(50,50,50,0.8)",
    900: "black",
  },
  grey: { 300: "#d1d1d1" },
  white: "#fff",
  green: {
    300: "white",
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#171923db",
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "#e1e1e1",
    },
  },
  gradient: {
    blue: {
      600: "rgb(180, 0, 0)",
      900: "#350b05",
      300: "red",
    },
  },
  text: {
    grey: {
      300: "white",
      500: "white",
    },
  },
  timeline: {
    divider: {
      bg: "#718096",
    },
  },
};

const ChannelItem = ({ channel }) => {
  const { position, logo, title, uuid } = channel;

  return (
    <ChannelBox {...position}>
      <ChannelLogo src={logo} data-tip={title} alt="Logo" />

      <ReactTooltip type="dark" />
    </ChannelBox>
  );
};

const Item = ({
  program,
  newChannels,
  PLATFORM,
  CHANNEL_DATA,
  LOADING,
  ADSDETAILS,
  EPG_DATE,
  IS_CATCHUP,
  PACKAGE_DETAILS,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,

  CURRENT_PLATFORM_DATA,

  DEVICE_TOKEN,
  CONFIGURATION_DAS,

  USER_DETAILS,
  AUTHENTICATION,
}) => {
  // alert(JSON.stringify(newChannels));
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    if (
      ads != undefined &&
      ads != null &&
      ads.length > 0 &&
      AUTHENTICATION &&
      modalShow == true
    ) {
      let data2 = {
        adsAnalyticsData: {
          addId: ads[0].id,
          userId: USER_DETAILS?.id,
          platformId: CURRENT_PLATFORM_DATA.orgId,
          profileId: 1,
          deviceId: DEVICE_TOKEN,
          categoryId: 1,
          assetId: CHANNEL_DATA[0]?.id,
        },
        ServerIp: CONFIGURATION_DAS?.analyticsIp,
      };
      getSendingDataOfPosteradsAsync(data2);
    }
  }, [modalShow]);
  const { styles, formatTime, isLive, isMinWidth } = useProgram({ program });

  const { data } = program;

  const {
    image,
    title,
    since,
    till,
    description,
    startTimeTimeStamp,
    stopTimeTimeStamp,
    channelUuid,
    assetUrl,
    assetId,
  } = data;
  const [catchup, setcatchup] = useState();
  let timeStamp = stopTimeTimeStamp - startTimeTimeStamp;
  let seconds = timeStamp / 1000;
  let url;
  let firstPartUrl;
  let uuid;
  let drmId;
  if (newChannels != undefined && newChannels != null) {
    uuid = newChannels?.uuid;
    url = assetUrl?.split(".");
    var pos = assetUrl?.lastIndexOf(".");
    drmId = newChannels?.drmId;
    firstPartUrl = assetUrl?.substring(0, pos);

    var startTimeStampAfterTrailingZeros = startTimeTimeStamp / 1000;
  }

  let isProgramLive =
    Date.now() > new Date(Number(startTimeTimeStamp)) &&
    Date.now() < new Date(Number(stopTimeTimeStamp));

  let AssetUrl;
  if (isProgramLive == false) {
    AssetUrl =
      firstPartUrl +
      "-" +
      startTimeStampAfterTrailingZeros +
      "-" +
      seconds +
      ".m3u8";
  } else {
    AssetUrl =
      firstPartUrl + "-" + startTimeStampAfterTrailingZeros + "-now" + ".m3u8";
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isImageError, setImageError] = useState(false);
  var liveTimestamp = Math.floor(new Date().getTime() / 1000);
  const [isProgramActive, setIsProgramActive] = useState(false);
  const [isProgramCatchup, setIsProgramCatchup] = useState(false);
  useEffect(() => {
    setImageSrc(CHANNEL_DATA?.[0]?.liveThumbnailPath + newImageString);
  }, [CHANNEL_DATA]);
  // let assetDetails = LIVEASSETS?.liveAssetDetails;

  // const element = assetDetails?.[item?.assetId];

  const onOpenModal = () => {
    let requestData = {
      id: assetId,
      account_id: PLATFORM?.currentPlatformId,
    };
    // if (element != undefined && element != null) {
    //   dispatch(VIDEO_ACTION.getLiveChannelData(element));
    // } else {
    //   dispatch(VIDEO_ACTION.getLiveDetailsStart(assetId));
    // }

    dispatch(DATA_ACTION.getLiveAssetsDataStart(requestData));
    // alert(startTimeTimeStamp);
    // alert(JSON.stringify(CHANNEL_DATA[0]?.catchupRequired));
    dispatch(
      VIDEO_ACTION.getDrmCatchupRequired(CHANNEL_DATA[0]?.catchupRequired)
    );
    let currentProgramStartTimeStamp = startTimeTimeStamp / 1000;
    if (liveTimestamp > currentProgramStartTimeStamp) {
      setIsProgramActive(true);
    }
    if (liveTimestamp > stopTimeTimeStamp / 1000) {
      // alert("catchup" + startTimeTimeStamp);
      setIsProgramCatchup(true);
    } else {
      // alert("not" + startTimeTimeStamp / 1000 + "   " + liveTimestamp);
      setIsProgramCatchup(false);
    }
    if (title != "Programme Info Not Available") {
      setOpen(true);
      setModalShow(true);
    }

    // alert(assetId);
  };

  const handleEpgVideo = () => {
    let flag = 0;
    // for (var p = 0; p < CHANNEL_DATA[0]?.packages?.length; p++) {
    //   if (CHANNEL_DATA[0]?.packages[p]?.assetGroupId == currentUserPackageId) {
    //     // packageId = CHANNEL_DATA[0].packages[p].assetGroupId;
    //     flag = 1;
    //   }
    // }
    let date = new Date();

    let timestamp = date?.getTime();
    //  ***************PACKAGE_DETAILS**************

    for (var p = 0; p < CHANNEL_DATA[0]?.packages?.length; p++) {
      let stopDate =
        PACKAGE_DETAILS?.[CHANNEL_DATA[0]?.packages?.[p]?.assetGroupId]
          ?.stopDate;

      let stopDateTimeStamp = parseInt(Date.parse(stopDate));

      if (
        CHANNEL_DATA[0]?.packages[p]?.assetGroupId ==
          PACKAGE_DETAILS?.[CHANNEL_DATA[0]?.packages[p]?.assetGroupId]
            ?.packageId &&
        date?.getTime() < stopDateTimeStamp
      ) {
        flag = 1;
      }
    }

    // ************ALACARTE_DETAILS***********************
    let stopDateAlacarte = ALACARTE_DETAILS?.[CHANNEL_DATA[0]?.id]?.expiryDate;
    let stopDateTimeStampAlacarte = parseInt(Date.parse(stopDateAlacarte));

    if (
      CHANNEL_DATA[0]?.id == ALACARTE_DETAILS?.[CHANNEL_DATA[0]?.id]?.assetId &&
      ALACARTE_DETAILS?.[CHANNEL_DATA[0]?.id]?.categoryId == 1 &&
      timestamp < stopDateTimeStampAlacarte
    ) {
      flag = 1;
    }
    // *****************PAY_PER_VIEW_DETAILS*************
    let stopDatePPV =
      PAY_PER_VIEW_DETAILS?.[1]?.[CHANNEL_DATA[0]?.id]?.expiryDate;
    let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

    if (
      CHANNEL_DATA[0]?.id ==
        PAY_PER_VIEW_DETAILS?.[1]?.[CHANNEL_DATA[0]?.id]?.assetId &&
      // PAY_PER_VIEW_DETAILS?.[CHANNEL_DATA[0]?.id]?.categoryId == 1 &&
      timestamp < stopDateTimeStampPPV
    ) {
      flag = 1;
    }

    dispatch(VIDEO_ACTION.getLiveVideoUrl({ videoUrl: AssetUrl }));
    dispatch(VIDEO_ACTION.getEpgVideoUrl(assetUrl));
    history.push({
      pathname: "/epgVideoPlayer",
      state: {
        flag,
      },
    });
  };

  const [imageScr, setImageSrc] = useState();
  const [imageErrorCount, setImageErrorCount] = useState(0);
  const [newImageString, setNewImageString] = useState();

  useEffect(() => {
    let testThumbnail = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(
      CHANNEL_DATA[0]?.liveThumbnailPath
    );
    // alert(testThumbnail + "  " + CHANNEL_DATA[0]?.liveThumbnailPath);
    if (testThumbnail == false) {
      setImageSrc(CHANNEL_DATA[0]?.liveThumbnailPath + "thumbnail.png");
    } else {
      setImageSrc(newImageIpPath + CHANNEL_DATA[0]?.liveThumbnailPath);
    }
  }, [CHANNEL_DATA]);
  useEffect(() => {
    if (imageErrorCount > 1) {
      setImageError(true);
      setImageSrc("images/login/black.png");
    }
  }, [imageErrorCount]);
  // let thumbnailUrl = CHANNEL_DATA?[0]?.thumbnails?[0].thumbnailUrl;

  let thumbnailDate = new Date(
    new Date().getTime() - (60000 * 60 * 5 + 60000 * 32)
  );
  let utcDate = thumbnailDate.getDate();

  var month = thumbnailDate.getUTCMonth() + 1; //months from 1-12
  var day = thumbnailDate.getUTCDate();
  var year = thumbnailDate.getUTCFullYear();

  // let fullDay = day;

  if (parseInt(month) <= 9) {
    month = "0" + month;
  }
  if (parseInt(utcDate) <= 9) {
    utcDate = "0" + utcDate;
  }
  let thumbnailPath = CHANNEL_DATA?.[0]?.thumbnails[0]?.thumbnailUrl;

  const utcStr = new Date().toUTCString();
  let utcTime = utcStr.split(":");

  let hur = utcTime[0];
  let min = utcTime[1] - 2;
  let sec = utcTime[2];
  if (parseInt(sec) < 20) {
    sec = "00";
  } else if (parseInt(sec) >= 20 && parseInt(sec) < 40) {
    sec = "20";
  } else {
    sec = "40";
  }
  if (parseInt(min) <= 9) {
    min = "0" + min;
  } else {
    min = min;
  }

  let splitTime = hur + min + sec;
  let newSplitTime = splitTime?.split(" ");

  const handleImage = () => {
    setImageErrorCount((prev) => prev + 1);
    let lastMinute = Number(min - 1);
    if (parseInt(lastMinute) <= 9) {
      lastMinute = "0" + lastMinute;
    }
    let splitTime = hur + lastMinute + sec;
    let newSplitTime = splitTime.split(" ");
  };
  let ads = [];

  let nextEpgData = null;
  if (
    CHANNEL_DATA != undefined &&
    CHANNEL_DATA != null &&
    CHANNEL_DATA.length > 0
  ) {
    if (
      ADSDETAILS[Number(CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId)] != null
    ) {
      ads?.push(
        ADSDETAILS[Number(CHANNEL_DATA[0]?.liveAssetsPostAds[0]?.adId)]
      );
    }

    if (
      CHANNEL_DATA[0]?.nextepg != undefined &&
      CHANNEL_DATA[0]?.nextepg != null &&
      CHANNEL_DATA[0]?.nextepg?.length > 0
    ) {
      let length = CHANNEL_DATA[0]?.nextepg?.length;

      nextEpgData = CHANNEL_DATA[0]?.nextepg[length - 1];
    }
  }

  var startTime1 = null;
  var stopTime1 = null;
  let ampm = null;
  let pmam = null;
  let newStartTimeStampDate = new Date(Number(startTimeTimeStamp));
  let newStopTimeStampDate = new Date(Number(stopTimeTimeStamp));

  let newStartTimeStampHour = newStartTimeStampDate.getHours();
  if (parseInt(newStartTimeStampHour) <= 9) {
    newStartTimeStampHour = "0" + newStartTimeStampHour;
  }
  let newStartTimeStampMinute = newStartTimeStampDate.getMinutes();
  if (parseInt(newStartTimeStampMinute) <= 9) {
    newStartTimeStampMinute = "0" + newStartTimeStampMinute;
  }

  let newStopTimeStampHour = newStopTimeStampDate.getHours();
  if (parseInt(newStopTimeStampHour) <= 9) {
    newStopTimeStampHour = "0" + newStopTimeStampHour;
  }
  let newStopTimeStampMinutes = newStopTimeStampDate.getMinutes();
  if (parseInt(newStopTimeStampMinutes) <= 9) {
    newStopTimeStampMinutes = "0" + newStopTimeStampMinutes;
  }

  if (newStartTimeStampDate.getHours() >= 12) {
    ampm = "PM";
  } else {
    ampm = "AM";
  }

  if (newStopTimeStampDate.getHours() >= 12) {
    pmam = "PM";
  } else {
    pmam = "AM";
  }

  useEffect(() => {
    if (LOADING == false) {
      setcatchup(CHANNEL_DATA?.[0]?.catchupRequired);
    }
  }, [LOADING]);

  return (
    <div>
      <ProgramBox width="100%" style={styles.position} onClick={onOpenModal}>
        <ProgramContent data-tip={title} width={styles.width} isLive={isLive}>
          <ProgramFlex>
            <ProgramStack>
              <ProgramTitle>
                {" "}
                {/* {since} - {till} */}
                {title}
              </ProgramTitle>
              <ProgramText>
                {formatTime(since)} - {formatTime(till)}
              </ProgramText>
            </ProgramStack>
          </ProgramFlex>
        </ProgramContent>
        <ReactTooltip type="dark" />
      </ProgramBox>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {" "}
          <CloseButton variant="white" onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          {LOADING == false ? (
            <div className="col-12">
              <div className="row">
                {CHANNEL_DATA != undefined &&
                CHANNEL_DATA != null &&
                CHANNEL_DATA?.length > 0 ? (
                  <h4
                    className="text-center css-1old884-ProgramTitle e1f8qikj3"
                    style={{
                      color: "white",
                      fontSize: "24px",
                      fontWeight: "bold",
                      fontFamily: "Montserrat,sans-serif",
                      color: "white",
                    }}
                  >
                    {CHANNEL_DATA[0]?.title}
                  </h4>
                ) : (
                  ""
                )}

                <div
                  className="col-6 p-0 m-0"
                  // style={{ colour: "white", backgroundColor: "blue" }}
                >
                  {isProgramCatchup === false ? (
                    isProgramActive == true ? (
                      <div className="hover-buttons">
                        {" "}
                        <button
                          onClick={handleEpgVideo}
                          className="glow-on-hover"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="currentColor"
                            class="bi bi-play-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.445 8L5.694 4.052a.5.5 0 0 0-.694.417v7.062a.5.5 0 0 0 .694.417l5.75-3.948a.5.5 0 0 0 0-.834z" />
                          </svg>
                          Play
                        </button>
                        {/* <img
                          src={playIcon}
                          style={{ height: "60px", cursor: "pointer" }}
                          onClick={handleEpgVideo}
                        /> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          color: "yellow",
                          fontFamily: "Montserrat,sans-serif",
                          // padding: "20px",
                        }}
                      >
                        Program available at :{" "}
                        {newStartTimeStampHour + ":" + newStartTimeStampMinute}
                      </div>
                    )
                  ) : catchup == 1 ? (
                    <div className="hover-buttons">
                      {" "}
                      <button
                        onClick={handleEpgVideo}
                        className="glow-on-hover"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-play-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.445 8L5.694 4.052a.5.5 0 0 0-.694.417v7.062a.5.5 0 0 0 .694.417l5.75-3.948a.5.5 0 0 0 0-.834z" />
                        </svg>
                        Play
                      </button>
                      {/* <img
                        src={playIcon}
                        style={{ height: "60px", cursor: "pointer" }}
                        onClick={handleEpgVideo}
                      /> */}
                    </div>
                  ) : (
                    <div
                      style={{
                        color: "red",
                        fontSize: "24",
                        fontFamily: "Montserrat,sans-serif",
                      }}
                      className="ml-1 mb-1"
                    >
                      {" "}
                      Program not available
                    </div>
                  )}
                  <div className="row">
                    <div
                      className="col-lg-12 col-xl-12 mb-2"
                      style={{
                        color: "white",
                        // fontSize: "18px",
                        // fontWeight: "bold",
                      }}
                    >
                      <span className="mt-2">Selected Program : </span>
                      {CHANNEL_DATA != undefined && CHANNEL_DATA != null ? (
                        <span className="mt-8">
                          <span
                            style={{
                              fontSize: "18px",
                              color: "yellow",
                              fontFamily: "Montserrat,sans-serif",
                            }}
                          >
                            {title}
                          </span>
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "18px",
                            color: "yellow",
                            fontFamily: "Montserrat,sans-serif",
                          }}
                        >
                          No available Data
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 row">
                    <h6 className="main-title">
                      <div>
                        {" "}
                        <span
                          className="p-0"
                          style={{
                            color: "white",
                            // fontSize: "10px",
                            fontFamily: "Montserrat,sans-serif",
                          }}
                        >
                          {newStartTimeStampHour +
                            ":" +
                            newStartTimeStampMinute}{" "}
                          -{" "}
                          {newStopTimeStampHour + ":" + newStopTimeStampMinutes}
                        </span>
                      </div>
                      <div className="mt-3" style={{ color: "white" }}>
                        {EPG_DATE}
                      </div>
                    </h6>
                  </div>
                  <div
                    className="col-sm-12 p-0"
                    style={{ marginTop: "0px", float: "left" }}
                  ></div>
                  <span
                    className="mt-4 "
                    style={{
                      color: "white",

                      fontFamily: "Montserrat,sans-serif",
                    }}
                  >
                    {description}
                  </span>
                </div>
                <div
                  className="col-lg-5"
                  // style={{ colour: "red", backgroundColor: "red" }}
                >
                  {" "}
                  <div
                    className="block-images position-relative "
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      display: "flex",
                      color: "white",
                    }}
                  >
                    {isProgramActive == true && isProgramCatchup == false ? (
                      <div
                        className="img-box d-flex align-items-center"
                        style={{
                          position: "relative",

                          boxShadow: "20px 20px 50px rgba(0, 0, 0, 0.5)",
                          borderRadius: "10px",
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                          backdropFilter: "blur(5px)",
                          width: "550px",
                          height: "250px",
                        }}
                      >
                        {CHANNEL_DATA != undefined &&
                        CHANNEL_DATA != null &&
                        CHANNEL_DATA?.length > 0 &&
                        isImageError == false ? (
                          <img
                            onError={handleImage}
                            src={imageScr}
                            style={{
                              width: "550px",
                              height: "250px",
                            }}
                            className="img-fluid"
                            alt=""
                          />
                        ) : (
                          <div></div>
                        )}
                        <div
                          className=""
                          style={{
                            zIndex: 200000,
                            position: "absolute",

                            // left: "40%",
                            // top: "35%",
                          }}
                        >
                          {CHANNEL_DATA != undefined &&
                          CHANNEL_DATA != null &&
                          CHANNEL_DATA?.length > 0 ? (
                            <img
                              src={newImageIpPath + thumbnailPath}
                              style={{
                                hieght: "80px",
                                width: "80px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        {CHANNEL_DATA != undefined &&
                        CHANNEL_DATA != null &&
                        CHANNEL_DATA?.length > 0 ? (
                          <div
                            style={{
                              hieght: "200px",
                              width: "200px",
                              paddingBottom: "10%",
                            }}
                          >
                            <img src={newImageIpPath + thumbnailPath} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "0px",
                    float: "left",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {ads != undefined && ads != null && ads.length > 0 ? (
                    <AdsPage ads={ads} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <NewLoadingPage />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export function Timeline({
  isBaseTimeFormat,
  isSidebar,
  dayWidth,
  hourWidth,
  numberOfHoursInDay,
  offsetStartHoursRange,
  sidebarWidth,
}) {
  const { time, dividers, formatTime } = useTimeline(
    numberOfHoursInDay,
    isBaseTimeFormat
  );

  const renderTime = (index) => (
    <TimelineBox key={index} width={hourWidth}>
      <TimelineTime>
        {formatTime(index + offsetStartHoursRange).toLowerCase()}
      </TimelineTime>
      <TimelineDividers>{renderDividers()}</TimelineDividers>
    </TimelineBox>
  );

  const renderDividers = () =>
    dividers != undefined && dividers != null && dividers.length > 0
      ? dividers.map((_, index) => (
          <TimelineDividers key={index} width={hourWidth} />
        ))
      : "";

  return (
    <TimelineWrapper
      dayWidth={dayWidth}
      sidebarWidth={sidebarWidth}
      isSidebar={isSidebar}
    >
      {time != undefined && time != null
        ? time.map((_, index) => renderTime(index))
        : ""}
    </TimelineWrapper>
  );
}

function EpgPage({
  EPG,
  EPG_DATE,
  EPG_STATE,
  PLATFORM,
  CHANNEL_DATA,
  LOADING,
  ADSDETAILS,
  EPG_LOADING,
  IS_CATCHUP,

  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
  PACKAGE_DETAILS,
  CURRENT_PLATFORM_DATA,

  DEVICE_TOKEN,
  CONFIGURATION_DAS,

  USER_DETAILS,
  AUTHENTICATION,
}) {
  const [channels, setChannels] = React.useState([]);
  const [epg, setEpg] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentDate, setCurrentDate] = useState();
  const [newChannels, setNewChannels] = useState();
  const [height, setHeight] = useState(100 * (450 / window.innerHeight));
  const channelsData = React.useMemo(() => channels, [channels]);
  const epgData = React.useMemo(() => epg, [epg]);
  const listInnerRef = useRef();

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const vhValue = (screenHeight * 66) / 100; // Assuming you want 65vh
  // console.log(vhValue + "vhValue");

  // console.log(screenWidth + "screenWidth");
  // console.log(screenHeight + "screenHeight");
  // we want a reference to the current DOM node

  const { getEpgProps, getLayoutProps, onScrollToNow, onScrollTop } = useEpg({
    channels: channelsData,
    epg: EPG.epg,
    startDate: EPG_DATE,
    theme: theme,
  });

  const handleFetchResources = React.useCallback(async () => {
    setIsLoading(true);
    const epg = EPG.epg;

    const channels = EPG.channels;
    setEpg(epg);

    setChannels(channels);
    setIsLoading(false);
  }, [EPG]);
  React.useEffect(() => {
    // alert("hjiiiiiiiii");
    handleFetchResources();
  }, [EPG?.epg?.length]);
  if (isLoading == true) {
    return <NewLoadingPage />;
  }
  //console.log("eeeeeee", JSON.stringify(EPG?.epg));
  return (
    <div className="epg-container">
      {" "}
      {/* Apply inline style to set width to 90% */}
      {EPG_STATE.isLoading == false ? (
        <div
          style={{
            // height: screenHeight < 825 ? "70vh" : "80vh",
            height: `${vhValue}px`, // Set the height dynamically based on vhValue

            width: "100%",
          }}
        >
          <EpgTabScroll onScrollToNow={onScrollToNow} />
          <Epg
            {...getEpgProps()}
            isLoading={EPG_LOADING}
            loader={<EpgLoadingPage />}
          >
            <Layout
              {...getLayoutProps()}
              renderProgram={({ program }) => (
                <div>
                  <Item
                    key={program.data.id}
                    program={program}
                    newChannels={newChannels}
                    PLATFORM={PLATFORM}
                    CHANNEL_DATA={CHANNEL_DATA}
                    LOADING={LOADING}
                    ADSDETAILS={ADSDETAILS}
                    EPG_DATE={EPG_DATE}
                    IS_CATCHUP={IS_CATCHUP}
                    PACKAGE_DETAILS={PACKAGE_DETAILS}
                    ALACARTE_DETAILS={ALACARTE_DETAILS}
                    PAY_PER_VIEW_DETAILS={PAY_PER_VIEW_DETAILS}
                    CURRENT_PLATFORM_DATA={CURRENT_PLATFORM_DATA}
                    DEVICE_TOKEN={DEVICE_TOKEN}
                    CONFIGURATION_DAS={CONFIGURATION_DAS}
                    USER_DETAILS={USER_DETAILS}
                    AUTHENTICATION={AUTHENTICATION}
                  />
                </div>
              )}
              renderChannel={({ channel }) => (
                setNewChannels(channel),
                (<ChannelItem key={channel.uuid} channel={channel} />)
              )}
            />
          </Epg>
        </div>
      ) : (
        <NewLoadingPage />
      )}
    </div>
  );
}
const mapStateToProps = createStructuredSelector({
  EPG: epgSelector,
  EPG_DATE: epgDateSelector,
  EPG_STATE: epgStateSelector,
  PLATFORM: platformsSelector,
  CHANNEL_DATA: getLiveAssetsDataSelector,
  LOADING: getLiveLoadingSelector,
  ADSDETAILS: getGeneralAdsDetailSelector,
  EPG_LOADING: getEpgLoadingSelector,
  IS_CATCHUP: getIsCatchupRequiredSelector,
  EPG_LOADING_STATUS: getEpgLoadingStatusSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  LIVEASSETS: allLiveAssetSelector,

  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,

  DEVICE_TOKEN: getDeviceTokenSelector,
  CONFIGURATION_DAS: configurationSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
});

export default connect(mapStateToProps)(EpgPage);
