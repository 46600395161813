/** @format */

import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "../loader/loadingPlugin.js"; // Import the loading plugin
import "../retryPlugin/retry.js"; // Import the loading plugin
// This imports the functional component from the previous sample.
import "videojs-contrib-eme";
import { createStructuredSelector } from "reselect";
import "./../videoPlayer.css";
import {
  getEpgVideoUrlSelector,
  getIsCatchupRequiredSelector,
  getLiveVideoUrlSelector,
} from "../../Redux/VideoRedux/selector";
import { getCurrentPlatformSelector } from "../../Redux/platforms/selectors";
import { getDrmDetailsSelector } from "../../Redux/DRM integration/selectors";
import * as PACKAGE_SUBSCRIPTION_ACTION from "../../Redux/package&subscriptions/actions";
import "videojs-hotkeys";
import { useHistory, useLocation } from "react-router-dom";
import "video.js/dist/video-js.css";
import "videojs-overlay/dist/videojs-overlay.css";
//import "./../skins/chrome/videojs.min.css";
import "./../skins/party/ima.css";
import swal from "sweetalert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "@sweetalert2/themes/dark/dark.scss";
import { connect, useDispatch } from "react-redux";
import { baseName, subscribeUrl } from "../IpConstantFile/IpConstantFile";
import { platformsSelector } from "../../Redux/platforms/selectors";
import {
  getDeviceNameSelector,
  getDeviceTokenSelector,
} from "../../Redux/deviceRedux/selectors";
import { getDeviceStatusSelector } from "../../Redux/Delete Device/selectors";
import { getLiveAssetsDataSelector } from "../../Redux/Notication Assets redux/selectors";
import { getPiPStateFromStorage, newImageIpPath, wsLive } from "../../App.js";
import * as DATA_ANALYTICS from "../../Redux/DATA-analytics/actions.js";
import { configurationSelector } from "../../Redux/DATA-analytics/selector.js";
import {
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../Redux/package&subscriptions/selector.js";
import {
  getGeneralAdsDetailSelector,
  getgeneralVideoAdsDetailsSelector,
} from "../../Redux/AllLiveDetails/selectors.js";
import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../Redux/authentication/selectors.js";
import { getDefaultPlatformsSelector } from "../../Redux/DefaultPlatform/selectors.js";
import { getSaveDeviceTockenSelector } from "../../Redux/SaveDeviceTocken/selectors.js";
import * as EPGADATA from "./EpgPlayerDataForPlugin.js";
import "../LatestVideoJsPlugin/MoviePlugin/moviePlugin.js";
import "../LatestVideoJsPlugin/PlatformLogoPlugin.js";
import "../LatestVideoJsPlugin/keywordControlPluginWithIcons.js";
import "../LatestVideoJsPlugin/replayButtonPlugin.js";

import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../services/datAnalytics/dataAnalytics.js";

const EpgPlayerNew = ({
  LIVE_VIDEO_URL,
  DRM_DETAILS,
  setIsVideoPlayer,
  CURRENT_PLATFORM,
  DEVICE_NAME,
  DEVICE_TOKEN,
  AUTHENTICATION,
  DEVICE_STATUS,
  CHANNEL_DATA,
  CONFIGURATION_DAS,
  PAY_PER_VIEW_DETAILS,
  VIDEO_ADS,
  USER_DETAILS,
  PACKAGE_DETAILS,
  DEFAULT_PLATFORM,
  SAVED_DEVICE_TOCKEN,
  GENERAl_POST_ADS,
}) => {
  document.title = CHANNEL_DATA?.[0]?.title;
  console.log("CHANNEL_DATA" + JSON.stringify(CHANNEL_DATA));
  let location = useLocation().state;
  var startTime, endTime;

  const dispatch = useDispatch();
  const [signInMessage, setSignInMessage] = useState(false);

  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);

  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);
  let platformLogo = newImageIpPath + CURRENT_PLATFORM?.productLogo;

  let adsEnabledForDefaultPlatform;
  if (DEFAULT_PLATFORM != undefined && DEFAULT_PLATFORM != null) {
    adsEnabledForDefaultPlatform = DEFAULT_PLATFORM?.data?.data?.isAdsEnabled;
  }
  const adsEnabled = AUTHENTICATION
    ? Object.values(PACKAGE_DETAILS).some(
        (item) => item?.assetGroup?.isAdsEnabled === 1
      )
    : adsEnabledForDefaultPlatform;

  let videoAdsArray = [];

  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    liveui: AUTHENTICATION === true && location?.flag !== 0 ? true : false,
    qualitySelector: false, // Disable quality selector

    fluid: true,
    preload: "auto",

    aspectRatio: "16:8",
    playlistPicker: true,

    controlBar: {
      volumePanel: {
        inline: false, // Horizontal volume control
      },
    },
    // autoplay: true, // Automatically start playback when the player loads
    // controls: true, // Display player controls (play, pause, etc.)
    // responsive: true, // Make the player responsive to changes in the window size
    // liveui: true, // Enable live-specific UI elements
    // qualitySelector: false, // Disable the quality selector
    // fluid: true, // Make the player fluid (responsive and maintaining aspect ratio)
    // preload: "auto", // Preload video content
    // seekToLiveEdge: true, // Seek to the live edge when loading the player
    // isLive: true, // Indicate that the source is a live stream
    // aspectRatio: "16:8", // Set the aspect ratio of the player
    // playlistPicker: true, // Enable playlist picker UI (assumed as custom functionality)
    // controlBar: {
    //   children: [
    //     "skipBackward", // Custom skip backward button
    //     "playToggle", // Play/pause button
    //     "skipForward", // Custom skip forward button
    //     "currentTimeDisplay", // Display the current time
    //     "progressControl", // Display and control the progress of the video
    //     "durationDisplay", // Display the duration of the video
    //     "muteToggle", // Mute/unmute button
    //     "volumeControl", // Volume control slider
    //     "pictureInPictureToggle", // Picture-in-Picture mode toggle
    //     "fullscreenToggle", // Fullscreen mode toggle
    //   ],
    // },
  };

  const history = useHistory();

  let navigateLocation = baseName + "epgContainer";
  let navPath = "/epgContainer";

  let player;
  const videoNode = useRef(null);
  let dataAnalytics = {};

  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let LiveIntervalForSendingDataToLocal = setInterval(
        () => {
          // Check if sessionStorage.getItem("buffering") is false
          if (sessionStorage.getItem("buffering") === "false") {
            let dataObject =
              JSON.parse(localStorage.getItem("MovieDataToLocal")) || {};

            if (
              dataAnalytics &&
              typeof dataAnalytics === "object" &&
              JSON.parse(localStorage.getItem("isPlaying")) == true
            ) {
              // console.log("dataAnalytics.duration" + dataAnalytics.duration);

              if (
                dataAnalytics.assetId !== undefined &&
                dataAnalytics.categoryId !== undefined
              ) {
                const uniqueKey =
                  dataAnalytics.assetId + "_" + dataAnalytics.categoryId;

                // If the data already exists in local storage, add the new duration to the existing one
                if (dataObject[uniqueKey]) {
                  dataObject[uniqueKey].duration += dataAnalytics.duration;
                } else {
                  // Otherwise, create a new entry in the object
                  dataObject[uniqueKey] = dataAnalytics;
                }

                // Update local storage with the modified object
                localStorage.setItem(
                  "MovieDataToLocal",
                  JSON.stringify(dataObject)
                );
              }
              startTime = new Date().getTime();
            }
          }
        },
        CONFIGURATION_DAS?.frequencyForStoringDataInApp == undefined
          ? 30000
          : CONFIGURATION_DAS?.frequencyForStoringDataInApp
      );

      return () => {
        clearInterval(LiveIntervalForSendingDataToLocal);
      };
    }
  }, []);
  useEffect(() => {
    if (
      AUTHENTICATION === true &&
      Number(CURRENT_PLATFORM?.isDasEnabled) == 1
    ) {
      let interval = setInterval(
        () => {
          let dataAnalyticsForAPI = {};
          if (
            CURRENT_PLATFORM != undefined &&
            CURRENT_PLATFORM != null &&
            USER_DETAILS != undefined &&
            USER_DETAILS != null &&
            DEVICE_TOKEN != null &&
            DEVICE_TOKEN != undefined
          ) {
            dataAnalyticsForAPI = {
              platformId: CURRENT_PLATFORM?.orgId,
              profileId: 1,
              deviceId: DEVICE_TOKEN,

              userId: USER_DETAILS?.id,
              assets: {},
            };
          }
          let dataObject = JSON.parse(localStorage.getItem("MovieDataToLocal"));
          if (dataObject && typeof dataObject === "object") {
            const keys = Object.keys(dataObject);
            let finalData = [];

            keys.forEach((a) => {
              finalData?.push(dataObject?.[a]);
            });

            // console.log("dataObject" + JSON.stringify(finalData));
            if (finalData != undefined && finalData != null) {
              dataAnalyticsForAPI.assets = JSON.stringify(finalData);
            }

            // console.log("MovieDataToLocal" + JSON.stringify(MovieData));
            // alert("in LIVE Player sending Server" + JSON.stringify(dataObject));

            let data2;
            if (
              USER_DETAILS != undefined &&
              USER_DETAILS != null &&
              CONFIGURATION_DAS != null &&
              CONFIGURATION_DAS != undefined
            ) {
              data2 = {
                dataAnalyticsForAPI: dataAnalyticsForAPI,
                ServerIp: CONFIGURATION_DAS?.analyticsIp,
              };
            }
            dispatch(DATA_ANALYTICS.sendingDataToDasStart(data2));
            // startTime = new Date().getTime();
          }
        },
        CONFIGURATION_DAS?.frequencyForSendingDataToServer == undefined
          ? 300000
          : Number(CONFIGURATION_DAS?.frequencyForSendingDataToServer) + 6500
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, []);
  useEffect(() => {
    player = videojs(videoNode.current, videoJsOptions);

    document.addEventListener("enterpictureinpicture", () => {
      // PiP mode entered
      setPiPStateInStorage(true);
    });

    document.addEventListener("leavepictureinpicture", () => {
      // PiP mode exited
      setPiPStateInStorage(false);
    });

    function setPiPStateInStorage(isPiPMode) {
      localStorage.setItem("pipState", JSON.stringify({ isPiPMode }));
    }
    player.eme();
    player.keywordControlPluginWithIcons();
    player.replayButtonPlugin();

    player.loadingPlugin();
    player.errorOverlayPlugin(navigateLocation);
    player.displayPlatFormLogo(platformLogo);

    CHANNEL_DATA[0]?.liveAssetsVideoAds?.map((a) => {
      let videoUrl = VIDEO_ADS[a?.adId]?.videoUrl;
      if (videoUrl != undefined && videoUrl != null) {
        if (!videoUrl?.includes("http")) {
          videoUrl = newImageIpPath + videoUrl;
        }
      }

      let data;
      if (Number(a?.isSkipRequired) !== 0) {
        data = {
          id: a.adId,
          src: videoUrl,
          time: Number(a?.duration),
          skip: Number(a?.skipBeyond), // Corrected inequality operator
        };
      } else {
        data = {
          id: a.adId,

          src: videoUrl,
          time: Number(a?.duration),
        };
      }

      if (
        VIDEO_ADS[a?.adId] != undefined &&
        VIDEO_ADS[a?.adId] != null &&
        adsEnabled
      ) {
        videoAdsArray?.push(data);
      }
    });

    if (videoAdsArray?.length > 0) {
      localStorage.setItem("IsVideoAdAvailable", 1);
    } else {
      localStorage.setItem("IsVideoAdAvailable", 0);
    }
    let Movie_Details;
    if (window.WebKitMediaKeys) {
      Movie_Details = EPGADATA.getPlayListForFairplay({
        CHANNEL_DATA,
        DRM_DETAILS,
        SAVED_DEVICE_TOCKEN,
        DEVICE_NAME,
        GENERAl_POST_ADS,
        videoAdsArray,
        LIVE_VIDEO_URL,
        AUTHENTICATION,
        USER_DETAILS,
      });
    } else if (window.MediaKeys) {
      Movie_Details = EPGADATA.getPlayList({
        CHANNEL_DATA,
        DRM_DETAILS,
        SAVED_DEVICE_TOCKEN,
        DEVICE_NAME,
        GENERAl_POST_ADS,
        videoAdsArray,
        LIVE_VIDEO_URL,
        AUTHENTICATION,
        USER_DETAILS,
      });
    } else {
      Movie_Details = EPGADATA.getPlayList({
        CHANNEL_DATA,
        DRM_DETAILS,
        SAVED_DEVICE_TOCKEN,
        DEVICE_NAME,
        GENERAl_POST_ADS,
        videoAdsArray,
        LIVE_VIDEO_URL,
        AUTHENTICATION,
        USER_DETAILS,
      });
    }

    const seekButtonStyle = document.createElement("style");

    document.head.appendChild(seekButtonStyle);
    if (AUTHENTICATION === true && location?.flag == 1) {
      seekButtonStyle.textContent =
        ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
    } else {
      seekButtonStyle.textContent =
        ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
    }
    player.moviePlugin(Movie_Details, 0);

    let i = 0;
    let time;
    let remainingTime;
    let j = 0;
    player.on("posterAdplaying", function(event) {
      // Access the data in the event's detail property

      const adData = event.detail;
      // console.log("adsAnalyticsData", adData); // This will log the ad id
      if (AUTHENTICATION) {
        let data2 = {
          adsAnalyticsData: {
            addId: adData.id,

            userId: USER_DETAILS?.id,

            platformId: CURRENT_PLATFORM?.orgId,

            profileId: 1,

            deviceId: DEVICE_TOKEN,

            categoryId: 1,
            assetId: Number(CHANNEL_DATA[0]?.id),
          },
          ServerIp: CONFIGURATION_DAS?.analyticsIp,
        };

        // console.log("adsAnalyticsData", data2); // This will log the ad id

        getSendingDataOfPosteradsAsync(data2);
      }
    });
    player.on("adplaying", function(event) {
      // const seekButtonStyle = document.createElement("style");
      // document.head.appendChild(seekButtonStyle);
      // seekButtonStyle.textContent =
      //   ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
      // Access the data in the event's detail property
      // localStorage.setItem("IsVideoAdAvailable", 1);

      seekButtonStyle.textContent =
        ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
    });
    player.on("ended", function() {
      // Pause the player
      player.pause();
    });
    player.on("adended", function(event) {
      const adData = event.detail;

      if (AUTHENTICATION) {
        let data2 = {
          adsAnalyticsData: {
            addId: adData.id,

            userId: USER_DETAILS?.id,

            platformId: CURRENT_PLATFORM?.orgId,

            profileId: 1,

            deviceId: DEVICE_TOKEN,

            categoryId: 1,
            assetId: Number(CHANNEL_DATA[0]?.id),
          },
          ServerIp: CONFIGURATION_DAS?.analyticsIp,
        };

        console.log("adsAnalyticsData", data2); // This will log the ad id

        getSendingDataOfVideoadsAsync(data2);
      }
      const seekButtonStyle = document.createElement("style");

      document.head.appendChild(seekButtonStyle);
      if (AUTHENTICATION === true && location?.flag == 1) {
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";
      } else {
        seekButtonStyle.textContent =
          ".vjs-skip-backward { display: none !important; } .vjs-skip-forward { display: none !important; }";
      }
      localStorage.setItem("IsVideoAdAvailable", 0);
    });

    player.on("waiting", function() {
      localStorage.setItem("isPlaying", false);
      sessionStorage.setItem("buffering", true);
      this.addClass("vjs-waiting");
    });
    // player.on("waiting", function() {
    //   this.addClass("vjs-waiting");
    //   // player.reconnect();
    // });
    player.on("playing", function() {
      localStorage.setItem("isPlaying", true);
      this.removeClass("vjs-waiting");
      sessionStorage.setItem("buffering", false);
      // player.reconnect();
    });
    player.on("error", function() {
      localStorage.setItem("isPlaying", false);

      var error = player.error();
      // console.log("Video.js Error:", error);
      player.pause();
    });
    // player.on("vroll", function(event, data) {
    //   var ad_id = data.id;
    //   var action = data.action;

    //   if (action == "completed" || action == "skipped") {
    //     // console.log("flagRef.current" + flagRef.current);
    //     localStorage.setItem("IsVideoAdAvailable", 0);
    //   }
    // });
    // player.on("seeking", function(e) {
    //   if (j == 0 && player.currentTime() != 0) {
    //     remainingTime = player.currentTime() - 30;
    //     j++;
    //   }
    //   if (player.currentTime() < remainingTime) {
    //     if (AUTHENTICATION === true) {
    //       if (location?.flag == 0) {
    //         setSignInMessage(true);

    //         // Swal.fire({
    //         //   icon: "warning",
    //         //   text:
    //         //     "Please subscribe or upgrade your package to view this content.",
    //         //   allowOutsideClick: () => !Swal.isLoading(),
    //         //   confirmButtonColor: "#3085d6",
    //         //   confirmButtonText: "Subscribe",
    //         //   cancelButtonText: "May be later",
    //         //   showCancelButton: true,
    //         //   showCloseButton: true,
    //         // }).then((result) => {
    //         //   if (result.isConfirmed) {
    //         //     window.open(subscribeUrl);
    //         //   }
    //         // });

    //         // window.location.href = navigateLocation;
    //       }
    //     }

    //     if (AUTHENTICATION === false) {
    //       setSignInMessage(true);
    //     }
    //     setTimeout(() => {
    //       if (AUTHENTICATION === true) {
    //         if (location?.flag == 0) {
    //           player.pause();

    //           history.replace(navPath);
    //         }
    //       }

    //       if (AUTHENTICATION === false) {
    //         player.pause();
    //         history.replace(navPath);
    //       }
    //     }, 1000);
    //   }
    // });

    player.on("loadeddata", function() {
      localStorage.setItem("inLiveVidioPlayer", "liveVideoPlayer");

      if (wsLive != null && location?.flag != 0) {
        localStorage.setItem("liveAssetId", CHANNEL_DATA[0]?.id);

        wsLive.send(
          JSON.stringify({
            type: "open",
            deviceId: DEVICE_TOKEN,
            assetId: CHANNEL_DATA[0]?.id,
          })
        );
        localStorage.setItem("ConcurrentliveAssetId", CHANNEL_DATA[0]?.id);
      }
    });
    player.on("loadedmetadata", function() {
      if (AUTHENTICATION === true && location?.flag != 0) {
        document.addEventListener("keydown", (event) => {
          if (event.key === " ") {
            if (player.paused()) {
              player.play();
            } else {
              player.pause();
            }
          }
        });
      }
      localStorage.setItem("isPlaying", true);

      startTime = new Date().getTime();
    });
    let n = 0;

    player.on("timeupdate", function(e) {
      // alert(CHANNEL_DATA[0]?.id);
      // console.log(JSON.stringify(CHANNEL_DATA[0]));

      if (
        CHANNEL_DATA[0]?.id ==
          PAY_PER_VIEW_DETAILS?.[1]?.[CHANNEL_DATA[0]?.id]?.assetId &&
        // PAY_PER_VIEW_DETAILS?.[
        //   CHANNEL_DATA[0]?.id
        // ]?.categoryId == 1 &&
        n == 0
      ) {
        let stopDatePPV =
          PAY_PER_VIEW_DETAILS?.[1]?.[CHANNEL_DATA[0]?.id]?.expiryDate;
        let stopDateTimeStampPPV = parseInt(Date.parse(stopDatePPV));

        if (
          CHANNEL_DATA[0]?.id ==
            PAY_PER_VIEW_DETAILS?.[1]?.[CHANNEL_DATA[0]?.id]?.assetId &&
          // PAY_PER_VIEW_DETAILS?.[
          //   CHANNEL_DATA[0]?.id
          // ]?.categoryId == 1 &&
          new Date()?.getTime() > stopDateTimeStampPPV &&
          n == 0
        ) {
          player.pause();
          history.replace("/container");
        }
        if (
          CHANNEL_DATA[0]?.id ==
            PAY_PER_VIEW_DETAILS?.[1]?.[CHANNEL_DATA[0]?.id]?.assetId &&
          // PAY_PER_VIEW_DETAILS?.[
          //   CHANNEL_DATA[0]?.id
          // ]?.categoryId == 1 &&
          new Date()?.getTime() > stopDateTimeStampPPV &&
          n == 0
        ) {
          n = 1;
          if (player?.isFullscreen()) {
            player?.exitFullscreen();
          }
          const isPiPMode = getPiPStateFromStorage();
          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          Swal.fire({
            icon: "warning",
            text: "Your subscription for this content has expired",
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
            // cancelButtonText: "May be later",
            // showConfirmButton:
            //   SELECTED_MOVIE_DATA?.isPPVRequired == 1 ? true : false,
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(
                PACKAGE_SUBSCRIPTION_ACTION.getPackageDetailsStart({
                  userId: USER_DETAILS?.id,
                })
              );
              // history.replace("/container");
              window.location.href = navigateLocation;
            }
          });
        }
      }
      player.on("play", function() {
        localStorage.setItem("isPlaying", true);
        // Additional actions when the video starts playing
      });
      player.on("pause", function() {
        localStorage.setItem("isPlaying", false);
        // Additional actions when the video is paused
      });
      endTime = new Date().getTime();
      var timeSpent;
      var analyticsDataArray = [];
      if (startTime !== null) {
        timeSpent = (endTime - startTime) / 1000;
        // Check if timeSpent is a valid number
        if (!isNaN(timeSpent) && isFinite(timeSpent)) {
          if (CHANNEL_DATA[0] !== null && CHANNEL_DATA[0] !== undefined) {
            dataAnalytics = {
              assetId: CHANNEL_DATA[0]?.id,
              categoryId: 1,
              duration: timeSpent,
            };
          }
          // Push the data or save it as needed
          // For example, assuming you have an array to store multiple analytics data
          analyticsDataArray.push(dataAnalytics);
        }
      }
      let videoAdsStatus = localStorage.getItem("IsVideoAdAvailable");

      // console.log("arun 1" + videoAdsStatus);
      // console.log("arun 2" + player.currentTime());
      if (i == 0 && videoAdsStatus == 0 && player.currentTime() > 0) {
        // console.log("arun 3" + player.currentTime());

        time = Number(player.currentTime()) + Number(30);
        i++;
        // console.log("arun   22  " + player.currentTime());
        // console.log("arun   33  " + time);
      }

      let initialDeffrence = Number(time) - Number(player.currentTime());
      if (initialDeffrence > 30) {
        let deffrence = initialDeffrence - 30;
        time = time - deffrence;
      }
      if (player.currentTime() > time) {
        // console.log("arun   44  " + player.currentTime());
        // console.log("arun   55  " + time);

        if (AUTHENTICATION === true) {
          if (location?.flag == 0) {
            setSignInMessage(true);
          }
        }

        if (AUTHENTICATION === false) {
          setSignInMessage(true);
        }

        setTimeout(() => {
          if (AUTHENTICATION === true) {
            if (location?.flag == 0) {
              // alert(location?.flag + "    " + navigateLocation);
              player.pause();

              history.replace(navPath);
            }
          }

          if (AUTHENTICATION === false) {
            player.pause();
            history.replace(navPath);
          }
        }, 1000);
      }
    });

    return () => {
      if (player) {
        const seekButtonStyle = document.createElement("style");

        document.head.appendChild(seekButtonStyle);
        seekButtonStyle.textContent =
          // ".vjs-rewind-control { display: block !important; } .vjs-forward-control { display: block !important; }";
          ".vjs-skip-backward { display: block !important; } .vjs-skip-forward { display: block !important; }";

        // console.log("player disposeddddddddddddd !!!!!!!!!!!!!!!");
        player.pause();
        // player.src(""); // empty string

        player?.dispose();
        videoNode.current = null;
      }
    };
  }, [videoNode]);

  useEffect(() => {
    if (signInMessage == true) {
      if (AUTHENTICATION === true) {
        if (location?.flag == 0) {
          if (player?.isFullscreen()) {
            player?.exitFullscreen();
          }
          const isPiPMode = getPiPStateFromStorage();
          if (isPiPMode === true) {
            document.exitPictureInPicture(); // Exit PiP mode when navigating back
          }
          Swal.fire({
            icon: "warning",
            text:
              "Please subscribe or upgrade your package to view this content.",
            allowOutsideClick: () => !Swal.isLoading(),
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Subscribe",
            cancelButtonText: "May be later",
            showCancelButton: true,
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(subscribeUrl);
              player.pause();
            }
          });
        }
      } else if (AUTHENTICATION === false) {
        const isPiPMode = getPiPStateFromStorage();

        if (isPiPMode === true) {
          document.exitPictureInPicture(); // Exit PiP mode when navigating back
        }
        Swal.fire({
          icon: "warning",
          // position: "top-end",
          text:
            "You are not Signed in. Please Sign in to continue watching the video.",
          allowOutsideClick: () => !swal.isLoading(),
          confirmButtonColor: "#3085d6",
          button: "OK",
        }).then((OK) => {});
      }
    }
  }, [signInMessage]);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
    zIndex: 999999999999,
  };
  return (
    <div className="container">
      <div data-vjs-player>
        <video
          ref={videoNode}
          preload="none"
          className="video-js vjs-fluid autoplay"
          controls
          autoplay
        ></video>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  LIVE_VIDEO_URL: getLiveVideoUrlSelector,

  CURRENT_PLATFORM: getCurrentPlatformSelector,
  DRM_DETAILS: getDrmDetailsSelector,
  EPG_URL: getEpgVideoUrlSelector,
  IS_CATCHUP: getIsCatchupRequiredSelector,
  PLATFORM_ID: platformsSelector,
  DEVICE_NAME: getDeviceNameSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  CHANNEL_DATA: getLiveAssetsDataSelector,
  CONFIGURATION_DAS: configurationSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  VIDEO_ADS: getgeneralVideoAdsDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  DEFAULT_PLATFORM: getDefaultPlatformsSelector,
  SAVED_DEVICE_TOCKEN: getSaveDeviceTockenSelector,
  GENERAl_POST_ADS: getGeneralAdsDetailSelector,
});

export default connect(mapStateToProps)(EpgPlayerNew);
