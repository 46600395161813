/** @format */

import React, { useEffect, useState } from "react";
import "./SeriesAssetViewPage.css";
import { createStructuredSelector } from "reselect";
import { getSeriesDataSelector } from "../../../Redux/VideoRedux/selector";
import { connect, useDispatch } from "react-redux";
import {
  getGeneralAdsDetailSelector,
  getgeneralGenreDetailsSelector,
  getgeneralLanguageDetailsSelector,
  getgeneralTagDetailsSelector,
} from "../../../Redux/AllLiveDetails/selectors";
import * as SERIES_ACTION from "./../../../Redux/SeriesGenre/actions";

import {
  authenticatedStatusSelector,
  userDetailsSelector,
} from "../../../Redux/authentication/selectors";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getFavouriteSelector } from "../../../Redux/FavouteDataRedux/selector";
import * as GET_FAVORITES_ACTION from "../../../Redux/FavouteDataRedux/action";
import {
  configurationSelector,
  continueWatchingSelector,
} from "../../../Redux/DATA-analytics/selector";
import {
  getAlacarteDetailsSelector,
  getPackageDetailsSelector,
  getPayPerViewDetailsSelector,
} from "../../../Redux/package&subscriptions/selector";
import * as FAVOURATE_ACTION from "../../../Redux/favourites/actions";
import * as MOVIE_VIDEO_ACTION from "../../../Redux/VideoRedux/action";
import ReactTooltip from "react-tooltip";
import * as MOVIE_DATA_ACTION from "../../../Redux/playerMovieData/action";
import AlertModal from "../../AlertModal";
import LandingScreenAlert from "../../LandingScreens/LandingScreenAlert";
import { getCurrentPlatformSelector } from "../../../Redux/platforms/selectors";
import ProgressBarForMovie from "../../progress-bar/ProgressBarForMovie";
import SeriesTabs from "../seriesPageFavourites/SeriesTabs";
import MovieScreenTeaserVideoPlayer from "../../MoviesScreens/MovieScreenTeaserVideoPlayer";
import { newImageIpPath } from "../../../App";
import * as SERIES_VIDEO_ACTION from "../../../Redux/VideoRedux/action";
import { checkSubscription } from "../../CommonFunctions/checkSubscription.js";
import { getDeviceStatusSelector } from "../../../Redux/Delete Device/selectors.js";
import AdsPage from "../../AdsPage.js";
import {
  getSendingDataOfPosteradsAsync,
  getSendingDataOfVideoadsAsync,
} from "../../../services/datAnalytics/dataAnalytics.js";
import { getDeviceTokenSelector } from "../../../Redux/deviceRedux/selectors.js";
import ReactShowMoreText from "react-show-more-text";

const SeriesAssetViewPage = ({
  setIsVideoPlayer,
  ASSET_DATA,
  GENERAL_GENRE_DETAILS,
  GENERAL_ADS_DETAILS,
  GENERAL_TAG_DETAILS,
  GENERAL_LANGUAGE_DETAILS,
  PACKAGE_DETAILS,
  USER_DETAILS,
  AUTHENTICATION,
  GET_FAVOURITES_DATA,
  CONTINUE_WATCHING_DATA,
  ALACARTE_DETAILS,
  PAY_PER_VIEW_DETAILS,
  setIsOpen,
  CURRENT_PLATFORM_DATA,
  DEVICE_STATUS,
  CONFIGURATION_DAS,
  DEVICE_TOKEN,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation().state;

  const [showOverlay, setShowOverlay] = useState(true);
  const [isFavourite, setIsFavourite] = useState();
  const [open, setOpen] = useState(false);

  const [isMuted, setIsMuted] = useState(false);
  const handleVolumeToggle = () => {
    setIsMuted(!isMuted);
  };
  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (AUTHENTICATION == true) {
      if (DEVICE_STATUS == true) {
        history.push("/login");
      }
    }
  }, [DEVICE_STATUS]);
  const [alertOpen, setAlertOpen] = useState(false);
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
    boxSizing: "border-box",
  };

  const colorDivStyle = {
    width: "15%",
    height: "100px",
    margin: "0 2px",
    display: "inline-block",
  };
  let flag = checkSubscription({
    ASSET_DATA,
    PACKAGE_DETAILS,
    categoryId: 3,
    ALACARTE_DETAILS,
    PAY_PER_VIEW_DETAILS,
  });
  // useEffect(() => {
  //   setIsVideoPlayer(true);
  //   const timer = setTimeout(() => {
  //     setShowOverlay(false);
  //   }, 10000); // Hide overlay after 10 seconds

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    setIsVideoPlayer(true);
  }, []);

  // useEffect(() => {
  //   const handleMouseMove = () => {
  //     setShowOverlay(true);
  //     setShowDetails(true);

  //     // Clear existing timeout if there's one
  //     if (mouseMoveTimeout) {
  //       clearTimeout(mouseMoveTimeout);
  //     }

  //     // Hide details after 3 seconds of inactivity
  //     const timeout = setTimeout(() => {
  //       setShowOverlay(false);
  //       setShowDetails(false);
  //     }, 10000);

  //     setMouseMoveTimeout(timeout);
  //   };

  //   window.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     if (mouseMoveTimeout) {
  //       clearTimeout(mouseMoveTimeout);
  //     }
  //   };
  // }, [mouseMoveTimeout]);

  //code of creating tag lang genre using general details
  let tag = [];
  let genre = [];
  let ads = [];
  let languages = [];
  for (var j = 0; j < ASSET_DATA?.seriesgenres?.length; j++) {
    genre?.push(
      GENERAL_GENRE_DETAILS?.[ASSET_DATA?.seriesgenres?.[j]?.genreId]
    );
  }

  for (var k = 0; k < ASSET_DATA?.seriestags?.length; k++) {
    tag?.push(GENERAL_TAG_DETAILS?.[ASSET_DATA?.seriestags[k]?.tagId]);
  }

  if (
    GENERAL_ADS_DETAILS?.[
      Number(
        ASSET_DATA?.seasons[0]?.seasonepisodes?.[0]?.episodePostAdv?.[0]?.adId
      )
    ] != null
  ) {
    ads?.push(
      GENERAL_ADS_DETAILS?.[
        Number(
          ASSET_DATA?.seasons[0]?.seasonepisodes?.[0]?.episodePostAdv?.[0]?.adId
        )
      ]
    );
  }

  useEffect(() => {
    if (ads?.length > 0 && AUTHENTICATION) {
      let data2 = {
        adsAnalyticsData: {
          addId: ads[0].id,
          userId: USER_DETAILS?.id,
          platformId: CURRENT_PLATFORM_DATA.orgId,
          profileId: 1,
          deviceId: DEVICE_TOKEN,
          categoryId: 3,
          assetId: ASSET_DATA?.id,
        },
        ServerIp: CONFIGURATION_DAS?.analyticsIp,
      };
      getSendingDataOfPosteradsAsync(data2);
    }
  }, []);

  languages?.push(GENERAL_LANGUAGE_DETAILS?.[ASSET_DATA?.languageId]);
  //end

  //function for playing the video
  // console.log("arunvn123" + JSON.stringify(ASSET_DATA?.seasons));
  // console.log("arunvn123" + JSON.stringify(CONTINUE_WATCHING_DATA));

  let total = 0;
  ASSET_DATA?.seasons?.forEach((season) => {
    total += season.seasonepisodes.length; // Assuming each season object has an 'episodes' array
  });

  const handleVideoPlay = ({ startOver, episodeId }) => {
    history.push({
      pathname: "/seriesEpisodeVideoPlayer",
      state: {
        flag,
        isPPVRequired: ASSET_DATA?.isPPVRequired,
        from: location.from,
        startOver,
        total,
        episodeId,
      },
    });
  };

  const handleFavourites = () => {
    dispatch(
      GET_FAVORITES_ACTION.getFavouriteDataStart({ userId: USER_DETAILS?.id })
    );
    if (
      USER_DETAILS != undefined &&
      USER_DETAILS != null &&
      AUTHENTICATION == true
    ) {
      setIsFavourite(!isFavourite);
      let favoriteData = {
        userId: USER_DETAILS?.id,
        categoryId: 3,
        assetId: ASSET_DATA?.id,
      };
      // console.log("favotateeeeeeeeeeeeee detailssssss", favoriteData);
      dispatch(FAVOURATE_ACTION.addFavouritesStart(favoriteData));
    } else {
      setAlertOpen(true);
    }
  };

  const handleTeaser = () => {
    history.push({
      pathname: "/teaserPlayer",

      state: {
        src: ASSET_DATA?.seasons?.[0]?.teaserUrl,
        posterUrl: newImageIpPath + ASSET_DATA?.thumbnailUrl,
      },
    });
  };
  let checkFavourite = false;
  let lengthList = null;
  useEffect(() => {
    setIsFavourite(checkFavourite);
  }, [checkFavourite]);

  if (
    GET_FAVOURITES_DATA != undefined &&
    GET_FAVOURITES_DATA != null &&
    GET_FAVOURITES_DATA != undefined
  ) {
    lengthList = GET_FAVOURITES_DATA?.length;
  }

  if (lengthList != null && ASSET_DATA != undefined && ASSET_DATA != null) {
    for (var i = 0; i < lengthList; i++) {
      if (
        GET_FAVOURITES_DATA[i]?.categoryId == 3 &&
        GET_FAVOURITES_DATA[i] != undefined &&
        GET_FAVOURITES_DATA[i] != null
      ) {
        if (Number(GET_FAVOURITES_DATA[i]?.assetId) == Number(ASSET_DATA?.id)) {
          checkFavourite = true;
        }
      }
    }
  }

  let allEpisodes = [];

  // Loop through each season in the seriesSeasons array
  ASSET_DATA?.seasons.forEach((season) => {
    // Concatenate the seasonepisodes from each season to the allEpisodes array
    allEpisodes = allEpisodes.concat(season.seasonepisodes);
  });
  // const seasons = ASSET_DATA?.seasons.map((season) => ({
  //   seasonId: season.id,
  //   seasonTitle: season.title,
  // }));

  // const seasons = ASSET_DATA?.seasons.reduce((acc, season) => {
  //   acc[season.id] = season.title;
  //   return acc;
  // }, {});

  const getSeasonsById = (seriesSeasons) => {
    const seasonsById = {};

    seriesSeasons.forEach((season) => {
      seasonsById[season.id] = season.title;
    });

    return seasonsById;
  };
  const seasons = getSeasonsById(ASSET_DATA?.seasons);

  const isContineuwatching = CONTINUE_WATCHING_DATA?.filter(
    (item) =>
      Number(item?.categoryId) === 3 &&
      Number(item?.assetId) === ASSET_DATA?.id &&
      Number(item?.isVideoEnded) === 0 &&
      Number(item?.videoDeleted) === 0 &&
      flag === 1 &&
      AUTHENTICATION == true
  );
  console.log("isContineuwatching", isContineuwatching);
  console.log("ASSET_DATA123", ASSET_DATA?.seasons);

  const matchedSeason = ASSET_DATA?.seasons?.find(
    (season) => season.id === isContineuwatching?.[0]?.seasonId
  );
  console.log("matchedSeason", matchedSeason);
  // Find the episode within the matched season that matches the episodeId
  const matchedEpisode = matchedSeason?.seasonepisodes?.find(
    (item) => item.id === isContineuwatching?.[0]?.episodeId
  );
  console.log("matchedEpisode", matchedEpisode);
  console.log("matchedEpisode", isContineuwatching?.[0]?.episodeId);

  let percentageViewed = 0;

  if (
    isContineuwatching != undefined &&
    isContineuwatching != null &&
    isContineuwatching.length > 0
  ) {
    const totalDurationInSeconds = convertTimeToSeconds(
      isContineuwatching?.[0].totalDuration
    );
    const durationInSeconds = convertTimeToSeconds(
      isContineuwatching?.[0]?.duration
    );
    percentageViewed = (durationInSeconds / totalDurationInSeconds) * 100;
  }

  const convertDurationToMinutes = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    // Calculate the total duration in minutes
    const totalMinutes = hours * 60 + minutes + Math.round(seconds / 60);

    return totalMinutes;
  };
  function convertTimeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  //end
  let text = "";

  if (ASSET_DATA != null && ASSET_DATA?.isPPVRequired == 0) {
    text = "Please subscribe or upgrade your package to make it as favourite.";
  } else {
    text =
      "This is a  Pay Per View Asset. You need to buy it on DishHome Portal to consume it";
  }
  if (alertOpen == true) {
    return <LandingScreenAlert open={alertOpen} setOpen={setAlertOpen} />;
  }
  if (open == true) {
    return <AlertModal open={open} setOpen={setOpen} text={text} />;
  }

  return (
    <div
      className="fullscreen-series-container p-0 m-0"
      style={{ backgroundColor: "black" }}
    >
      {ASSET_DATA?.seasons?.[0]?.teaserUrl != undefined &&
      ASSET_DATA?.seasons?.[0]?.teaserUrl != null ? (
        <MovieScreenTeaserVideoPlayer
          posterUrl={newImageIpPath + ASSET_DATA?.thumbnailUrl}
          teaserUrl={ASSET_DATA?.seasons?.[0]?.teaserUrl}
          CURRENT_PLATFORM={CURRENT_PLATFORM_DATA}
          isMuted={isMuted}
        />
      ) : ASSET_DATA != undefined &&
        ASSET_DATA != null &&
        ASSET_DATA?.teaserUrl != undefined &&
        ASSET_DATA?.teaserUrl != null ? (
        <MovieScreenTeaserVideoPlayer
          posterUrl={newImageIpPath + ASSET_DATA?.thumbnailUrl}
          teaserUrl={ASSET_DATA?.teaserUrl}
          CURRENT_PLATFORM={CURRENT_PLATFORM_DATA}
          isMuted={isMuted}
        />
      ) : ASSET_DATA?.seasons?.[0]?.thumbnailUrl != undefined &&
        ASSET_DATA?.seasons?.[0]?.thumbnailUrl != null ? (
        <div className="img-box" style={{ marginLeft: "90px" }}>
          <img
            src={newImageIpPath + ASSET_DATA?.seasons?.[0]?.thumbnailUrl}
            style={{
              width: "100px", // increased width
              height: "700px", // increased height
              opacity: 1,
              filter: "blur(5px)", // adding blur effect
            }}
            className="img-fluid"
            alt=""
          />
        </div>
      ) : (
        <div className="img-box" style={{ marginLeft: "90px" }}>
          <img
            src={ASSET_DATA?.thumbnailUrl}
            style={{
              width: "1300px", // increased width
              height: "700px", // increased height
              opacity: 1,
              filter: "blur(5px)", // adding blur effect
            }}
            className="img-fluid"
            alt=""
          />
        </div>
      )}

      <div
        className={`overlay ${showOverlay ? "visible" : "hidden"}`}
        style={{
          display: "flex",
          flexDirection: "column", // Stack the divs vertically
        }}
      >
        <span
          className="mute-unmute"
          style={{
            position: "absolute",
            top: "10px", // Move up by decreasing the top value
            right: "20px",
            cursor: "pointer",
          }}
          onClick={() => handleVolumeToggle()}
        >
          {isMuted ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white" // Change icon color to white
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-volume-x"
              style={{ cursor: "pointer" }}
            >
              <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
              <line x1="23" y1="9" x2="17" y2="15"></line>
              <line x1="17" y1="9" x2="23" y2="15"></line>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white" // Change icon color to white
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-volume-2"
              style={{ cursor: "pointer" }}
            >
              <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
              <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
            </svg>
          )}
        </span>
        <span
          className="go-back"
          style={{
            position: "absolute",
            top: "10px", // Move up by decreasing the top value
            left: "20px",
            cursor: "pointer",
          }}
          onClick={() => handleGoBack()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white" // Change icon color to white
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-left-circle"
            style={{ cursor: "pointer" }}
          >
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 8 8 12 12 16"></polyline>
            <line x1="16" y1="12" x2="8" y2="12"></line>
          </svg>
        </span>
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            top: -10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div className="content">
              {ASSET_DATA != undefined &&
              ASSET_DATA != null &&
              ASSET_DATA?.title != undefined &&
              ASSET_DATA?.title != null ? (
                <h1
                  className="title"
                  style={{ color: "white", marginTop: "0px" }}
                >
                  {ASSET_DATA.title}
                </h1>
              ) : (
                ""
              )}{" "}
              <div
                className="col-sm-12 p-0"
                style={{ marginTop: "0px", float: "left" }}
              >
                {ASSET_DATA != undefined ? (
                  <div
                    className="col-sm-12 p-0"
                    style={{
                      fontSize: "18px",
                      color: "white",
                      marginTop: "0px",
                      fontFamily:
                        "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                    }}
                  >
                    {ASSET_DATA?.shortDescription}
                  </div>
                ) : (
                  ""
                )}

                {ASSET_DATA?.longDescription ? (
                  <ReactShowMoreText
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="long-description"
                    anchorClass="show-more-anchor" // Apply custom class here
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {ASSET_DATA?.longDescription}
                  </ReactShowMoreText>
                ) : (
                  ""
                )}

                <span
                  style={{
                    color: "#999",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    marginRight: "10px", // Add margin to create a gap between spans

                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  {ASSET_DATA?.yearOfRelease}
                </span>
                {ASSET_DATA?.duration && (
                  <span
                    style={{
                      color: "white",

                      backgroundColor: "#33373d",
                      fontSize: "14px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      fontFamily:
                        "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                      borderRadius: "5px", // Add this line for border radius
                      padding: "5px", // Optional: add padding for better appearance
                      marginRight: "10px", // Add margin to create a gap between spans
                    }}
                  >
                    {ASSET_DATA?.duration}
                  </span>
                )}
                <span
                  style={{
                    color: "white",

                    backgroundColor: "#33373d",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                    borderRadius: "5px", // Add this line for border radius
                    padding: "5px", // Optional: add padding for better appearance
                    marginRight: "10px", // Add margin to create a gap between spans
                  }}
                >
                  {ASSET_DATA?.maturityRating}
                </span>
                {languages != undefined &&
                  languages != null &&
                  languages?.length > 0 &&
                  languages?.map((i) => (
                    <span
                      style={{
                        color: "white",

                        backgroundColor: "#33373d",
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginTop: "10px",
                        fontFamily:
                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                        borderRadius: "5px", // Add this line for border radius
                        padding: "5px", // Optional: add padding for better appearance
                        marginRight: "10px", // Add margin to create a gap between spans
                      }}
                    >
                      {" " + i?.name}
                    </span>
                  ))}
                <br />

                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Actors :{" "}
                </span>
                <span
                  style={{
                    color: "white",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  {ASSET_DATA?.actors}
                </span>
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Director :{" "}
                </span>
                <span
                  style={{
                    color: "white",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  {ASSET_DATA?.directors}
                </span>
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Tags :{" "}
                </span>
                {tag != undefined && tag != null && tag.length > 0
                  ? tag.map((item, index) => {
                      if (index == 0) {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {item?.tag}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {"|" + item?.tag}
                          </span>
                        );
                      }
                    })
                  : ""}
                <br />
                <span
                  style={{
                    color: "white",
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily:
                      "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                  }}
                >
                  Genres :{" "}
                </span>
                {genre.length > 0
                  ? genre.map((item, index) => {
                      if (index == 0) {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {item?.name}
                          </span>
                        );
                      } else {
                        return (
                          <span
                            style={{
                              color: "white",
                              fontFamily:
                                "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                            }}
                            key={index}
                          >
                            {", " + item?.name}
                          </span>
                        );
                      }
                    })
                  : ""}
                <br />
              </div>
              <div className="col-12 mt-0 p-0 ml-0">
                <div
                  className="col-12 p-0 m-0 row"
                  style={{ float: "left", zIndex: "9999" }}
                >
                  <div className="col-3 p-0 mt-3">
                    <ReactTooltip type="dark" />
                  </div>

                  {/* Parent container to stack buttons and icons */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* Play button and other buttons */}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100px",
                      }}
                    >
                      <div style={{ flex: "0 0 20%" }}>
                        <div className="hover-buttons">
                          {AUTHENTICATION === true &&
                          Number(
                            CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                          ) === 1 &&
                          Number(USER_DETAILS?.viewing_analytics_status) ===
                            1 &&
                          isContineuwatching != undefined &&
                          isContineuwatching != null &&
                          isContineuwatching.length > 0 ? (
                            <button
                              onClick={() => {
                                dispatch(
                                  SERIES_ACTION.getSeriesEpisodeDetails({
                                    episodes: allEpisodes,
                                    index: matchedEpisode?.id,
                                    assetTitle: ASSET_DATA?.title,
                                    allseasons: seasons,
                                  })
                                );
                                handleVideoPlay({
                                  startOver: 1,
                                  episodeId: matchedEpisode?.id,
                                });
                              }}
                              className="glow-on-hover"
                            >
                              Continue Watching
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                dispatch(
                                  SERIES_ACTION.getSeriesEpisodeDetails({
                                    episodes: allEpisodes,
                                    index:
                                      ASSET_DATA?.seasons[0].seasonepisodes[0]
                                        .id,
                                    assetTitle: ASSET_DATA?.title,
                                    allseasons: seasons,
                                  })
                                );
                                handleVideoPlay({
                                  startOver: 0,
                                  episodeId:
                                    ASSET_DATA?.seasons[0].seasonepisodes[0].id,
                                });
                              }}
                              className="glow-on-hover"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                fill="currentColor"
                                className="bi bi-play-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.445 8L5.694 4.052a.5.5 0 0 0-.694.417v7.062a.5.5 0 0 0 .694.417l5.75-3.948a.5.5 0 0 0 0-.834z" />
                              </svg>
                              Play
                            </button>
                          )}
                        </div>
                      </div>
                      {/* Other icons */}
                      {isContineuwatching != undefined &&
                        isContineuwatching != null &&
                        isContineuwatching.length > 0 && (
                          <div className="col-3 mt-4">
                            <div className="col-12 p-0 m-0">
                              {AUTHENTICATION === true &&
                                Number(
                                  CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                ) == 1 &&
                                Number(
                                  USER_DETAILS?.viewing_analytics_status
                                ) == 1 &&
                                isContineuwatching != undefined &&
                                isContineuwatching != null &&
                                isContineuwatching.length > 0 && (
                                  <div
                                    className="col-12 p-0 m-0"
                                    style={{
                                      whiteSpace: "nowrap",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      height: "100%",
                                      top: -12,
                                    }}
                                  >
                                    <div
                                      className="p-0 m-0"
                                      style={{
                                        color: "white",
                                        fontFamily:
                                          "Franklin Gothic Medium, Arial Narrow, Arial, sans-serif",
                                      }}
                                    >
                                      {matchedEpisode?.title}
                                    </div>
                                    <div className="mb-2">
                                      <div
                                        className=""
                                        data-tip={
                                          isContineuwatching != undefined &&
                                          isContineuwatching != null &&
                                          isContineuwatching.length > 0
                                            ? `${convertDurationToMinutes(
                                                isContineuwatching?.[0]
                                                  ?.totalDuration
                                              ) -
                                                convertDurationToMinutes(
                                                  isContineuwatching?.[0]
                                                    ?.duration
                                                )} Min Remaining`
                                            : ""
                                        }
                                      >
                                        {AUTHENTICATION === true &&
                                        Number(
                                          CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                        ) == 1 &&
                                        Number(
                                          USER_DETAILS?.viewing_analytics_status
                                        ) == 1 &&
                                        isContineuwatching != undefined &&
                                        isContineuwatching != null &&
                                        isContineuwatching.length > 0 ? (
                                          <ProgressBarForMovie
                                            completed={percentageViewed}
                                            height="40px"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {AUTHENTICATION === true &&
                                      Number(
                                        CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                                      ) == 1 &&
                                      Number(
                                        USER_DETAILS?.viewing_analytics_status
                                      ) == 1 &&
                                      isContineuwatching != undefined &&
                                      isContineuwatching != null &&
                                      isContineuwatching.length > 0 ? (
                                        <span className="mb-2">
                                          {convertDurationToMinutes(
                                            isContineuwatching?.[0]?.duration
                                          )}{" "}
                                          of{" "}
                                          {convertDurationToMinutes(
                                            isContineuwatching?.[0]
                                              ?.totalDuration
                                          )}{" "}
                                          Min
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}{" "}
                      {AUTHENTICATION === true &&
                        Number(
                          CURRENT_PLATFORM_DATA?.viewing_analytics_enabled
                        ) == 1 &&
                        Number(USER_DETAILS?.viewing_analytics_status) == 1 &&
                        isContineuwatching != undefined &&
                        isContineuwatching != null &&
                        isContineuwatching.length > 0 && (
                          <div style={{ flex: "0 0 10%" }}>
                            {/* Start Over Button */}
                            <div
                              className="icon-wrapper-fav"
                              data-tip="Trailer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="60"
                                viewBox="0 0 24 24"
                                width="45"
                                data-tip="Start over"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  dispatch(
                                    SERIES_ACTION.getSeriesEpisodeDetails({
                                      episodes: allEpisodes,
                                      index: matchedEpisode?.id,
                                      assetTitle: ASSET_DATA?.title,
                                      allseasons: seasons,
                                    })
                                  );
                                  handleVideoPlay({
                                    startOver: 0,
                                    episodeId: matchedEpisode?.id,
                                  });
                                }}
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                  d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"
                                  fill="#FFFFFF"
                                />
                              </svg>
                            </div>
                          </div>
                        )}
                      <div style={{ flex: "0 0 10%" }}>
                        {/* Teaser Button */}
                        <div
                          className="icon-wrapper-fav"
                          data-tip="Trailer"
                          onClick={handleTeaser}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="44px"
                            viewBox="0 -960 960 960"
                            width="44px"
                            fill="white"
                          >
                            <path d="M160-120q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v560q0 33-23.5 56.5T800-120H160Zm0-80h80v-80h-80v80Zm560 0h80v-80h-80v80Zm-280-80h80v-240h-80v240Zm-280-80h80v-80h-80v80Zm560 0h80v-80h-80v80ZM160-520h80v-80h-80v80Zm560 0h80v-80h-80v80Zm-240-80q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm-320-80h80v-80h-80v80Zm560 0h80v-80h-80v80ZM320-200h320v-560H320v560Zm0-560h320-320Z" />
                          </svg>
                        </div>
                      </div>
                      <div style={{ flex: "0 0 10%" }}>
                        {/* Favorite Icon */}
                        <div className="col-2" style={{ cursor: "pointer" }}>
                          {USER_DETAILS != undefined &&
                          USER_DETAILS != null &&
                          AUTHENTICATION == true ? (
                            <div
                              className="icon-wrapper-fav"
                              onClick={handleFavourites}
                            >
                              {isFavourite ? (
                                <img
                                  height="40px"
                                  width="40px"
                                  src="images/login/h4.png"
                                  alt="Favorite icon"
                                />
                              ) : (
                                <img
                                  height="40px"
                                  width="40px"
                                  src="images/login/h3.png"
                                  alt="Not favorite icon"
                                />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    {/* AdsPage below the buttons */}
                    {ads && ads.length > 0 ? (
                      <div
                        className="ads-container col-12"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          width: "100%",
                          bottom: "2", // Position at the bottom of the container
                          zIndex: 4,
                          top: -20,
                          left: -30,
                          // marginTop: "20px", // Add some spacing between icons and AdsPage
                          // marginBottom:
                          //   ASSET_DATA?.longDescription.length > 250
                          //     ? "200px"
                          //     : "0px",
                        }}
                      >
                        <div className="col-12">
                          <AdsPage ads={ads} />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>

          <div style={{ flex: 1 }}>
            {/* Bottom Section */}
            <div className="">
              <SeriesTabs
                seriesSeasons={ASSET_DATA?.seasons}
                flag={flag}
                isPPVRequired={ASSET_DATA?.isPPVRequired}
                from={location.from}
              />
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  ASSET_DATA: getSeriesDataSelector,
  GENERAL_GENRE_DETAILS: getgeneralGenreDetailsSelector,
  GENERAL_ADS_DETAILS: getGeneralAdsDetailSelector,
  GENERAL_TAG_DETAILS: getgeneralTagDetailsSelector,
  GENERAL_LANGUAGE_DETAILS: getgeneralLanguageDetailsSelector,
  USER_DETAILS: userDetailsSelector,
  AUTHENTICATION: authenticatedStatusSelector,
  GET_FAVOURITES_DATA: getFavouriteSelector,
  CONTINUE_WATCHING_DATA: continueWatchingSelector,
  PACKAGE_DETAILS: getPackageDetailsSelector,
  ALACARTE_DETAILS: getAlacarteDetailsSelector,
  PAY_PER_VIEW_DETAILS: getPayPerViewDetailsSelector,
  CURRENT_PLATFORM_DATA: getCurrentPlatformSelector,
  DEVICE_STATUS: getDeviceStatusSelector,
  DEVICE_TOKEN: getDeviceTokenSelector,
  CONFIGURATION_DAS: configurationSelector,
});
export default connect(mapStateToProps)(SeriesAssetViewPage);
